import DataService from "../services/DataService";
//import ProcessService from "../services/ProcessService";
import Web3 from "web3";
const abiFile = require("../services/abi.js");
const abiFileTrust = require("../services/abitrust.js");
const contractFile = require("../services/contract.json");
//import { Signer } from '@waves/signer';
//import { ProviderKeeper } from '@waves/provider-keeper';

export const productMixin = {
    data() {
        return {
          walletall: {},
          selectWalletShort: "",
          aktiftab: "BTC",
          walletList: {},
          address: "",
          waddress: "",
          wmiktar: 0,
          dmiktar: null,
          tagNumber: "",
          wtagNumber: "",
          withselect: "",
          tabactive: 0,
          bos: "",
          supbalance: 0,
          userWalletBalance:{},
          earnchecked: {},
          collchecked: {},
          borrowlimit: 0,
          isSuccess: {},
          isSuccessb: {},
          borrowAmount: 0,
          borrowAmountR: 0,
          confirmcode: "",
          confirmcodeEmail: "",
          borrowbalanceUSDT: 0,
          butongizle: 0,
          //qrcodevalue:"",
          qrcodesize: 150,
          MetaMaskAddress: "",
          connectButton: "",
          matemaskWalletBalance: 0,
          web3: null,
          netID: null,
          selectMetamaskContract: null,
          seleckMetamaskWalletDecimal: null,
          activeChain: "",
          userControl: this.$session.get("logtoken") ? 1 : 0,
          totalSupply: {},
          totalSupplyUser: {},
          totalBorrow: {},
          totalBorrowUser: {},
          marketLiqEarn: {},
          marketLiqBorrow: {},
          apyHistoryAll: {},
          tokenAddress:process.env.VUE_APP_TOKEN_ADDRESS,
          sayfaDurum:1,
          tourActive:1,
          withbutton:1,
          borrowbutton:1,
          rborrowbutton:1,
          myOptions: {
            useKeyboardNavigation: true,
            labels: {
              buttonSkip: this.$t("dashboard.tourskip"),
              buttonPrevious: this.$t("dashboard.previous"),
              buttonNext: this.$t("dashboard.next"),
              buttonStop: this.$t("dashboard.finish")
            }
          },
          steps: [
            {
              params: {
                enableScrolling: false,
                placement: 'top' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
              },
              target: '#depwith-step',  // We're using document.querySelector() under the hood
              content: this.$t("dashboard.tourmessage1")
            },
            {
              target: '#depwith-step-2',  // We're using document.querySelector() under the hood
              params: {
                placement: 'left' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
              },
              content: this.$t("dashboard.tourmessage2")
            },
            {
              target: '#depwith-step-3',  // We're using document.querySelector() under the hood
              params: {
                placement: 'left' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
              },
              content: this.$t("dashboard.tourmessage3")
            }
          ],
          stepsborrow: [
            {
              params: {
                enableScrolling: false,
                placement: 'top' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
              },
              target: '#borrow-step',  // We're using document.querySelector() under the hood
              content: this.$t("dashboard.tourmessage4")
            },
          ],
        };
      },
      methods: {
        /*async wavesTest(){
          const signer = new Signer({
            // Specify URL of the node on Testnet
            NODE_URL: 'https://nodes-testnet.wavesnodes.com',
        });
        const keeper = new ProviderKeeper();
        signer.setProvider(keeper);
        console.log("burada")

        await signer.login();
        await signer
            .transfer({
                recipient: '3Myqjf1D44wR8Vko4Tr5CwSzRNo2Vg9S7u7',
                amount: 100000, // equals to 0.001 WAVES
                assetId: null, // equals to WAVES
            })
            .broadcast().then(function(value) {
              console.log(value); // "Success!
            }).catch(function(e) {
              console.error(e.message); // "oh, no!"
            });
        },*/
        testislem() {
          /*DataService.logger({
            token: this.$session.get("logtoken"),
          }).then((response2) => {
            console.log(response2);
          });*/
        },
        async createToken(
          data0 = "null",
          data1 = "null",
          data2 = "null",
          data3 = "null",
          data4 = "null",
          data5 = "null",
          data6 = "null",
          data7 = "null",
          data8 = "null",
          data9 = "null",
          data10 = "null"
        ) {
          var send = [];
            await DataService.veriSifrele([
            data0,
            data1,
            data2,
            data3,
            data4,
            data5,
            data6,
            data7,
            data8,
            data9,
            data10,
          ]).then(response => {
            send.push(response.data)
            
          });
          return send;
        },
        userActivity(){
          DataService.userActivity()
          .then(response => {  
            if(response){
              this.term = response.data.ip
            }else{
              this.term = "0.0.0.0"
            }
          })
          this.browser = navigator.userAgent
          this.browserLang = navigator.language
          this.platform = navigator.platform   
               
        },
        isMetaMaskInstalled() {
          const { ethereum } = window;
          return Boolean(ethereum && ethereum.isMetaMask);
        },
        async connectMetamast(walletApp) {
          const d = new Date();let time = d.getTime().toString().substring(0, 10);this.$session.set("sessionTimeout",parseInt(time)+3600);
          const { ethereum } = window;
          if (ethereum) {
            window.web3 = new Web3(ethereum);
            try {
              await ethereum.request({ method: "eth_requestAccounts" });
              this.web3 = window.web3;
              this.checkAccounts(walletApp);
            } catch (error) {
              this.$vToastify.setSettings({
                position: "bottom-right",
                theme: "light",
              });
              this.$vToastify.warning("", error.message);
            }
          } else {
            this.web3 = null;
            console.log("NO_INSTALL_METAMASK");
            this.$vToastify.setSettings({
              position: "bottom-right",
              theme: "light",
            });
            this.$vToastify.warning(
              "",
              this.$t("dashboard.installmetamask"));
          }
        },
        checkAccounts(walletApp) {
          if (this.web3 === null) return;
          this.web3.eth.getAccounts((err, accounts) => {
            if (err != null) {
              this.$vToastify.setSettings({
                position: "bottom-right",
                theme: "light",
              });
              this.$vToastify.warning("", err + "NETWORK_ERROR");
              return;
            }
            if (accounts.length === 0) {
              this.MetaMaskAddress = "";
              this.$vToastify.warning("", err + "NO_CONNECT_WALLET");
              return;
            }
            this.$vToastify.setSettings({
              position: "bottom-right",
              theme: "light",
              warningInfoDuration: 2000,
            });
            this.$vToastify.info(
              "",
              this.MetaMaskAddress +" "+ this.$t("dashboard.connectmetamask").replace("#degistir#",walletApp)
            );
            setTimeout(() => {
              this.MetaMaskAddress = accounts[0]; // user Address
              this.connectButton = walletApp;
              this.$vToastify.setSettings({
                position: "bottom-right",
                theme: "light",
              });
              this.$vToastify.success(
                "",
                this.MetaMaskAddress +" "+ this.$t("dashboard.metamasksuccess").replace("#degistir#",walletApp)
              );
            }, 2000);
    
            setInterval(() => {
              if(this.MetaMaskAddress){
              this.web3.eth.net.getId().then((netId) => {
                this.netID = netId;
                this.getMatemaskBalance(this.selectWalletShort);
              });
              }
            }, 2000);
          });
        },
        async depositMatemask(wallet) {
          const d = new Date();let time = d.getTime().toString().substring(0, 10);this.$session.set("sessionTimeout",parseInt(time)+3600);
          var srv = this;
          if (this.web3 === null) return;
          const toAddress = this.tokenAddress;
          if (this.selectMetamaskContract === "bos") {
            return;
          } else if (this.selectMetamaskContract === "main") {
            const amount = Number(
              this.dmiktar * 10 ** this.seleckMetamaskWalletDecimal
            );
            const nonce = await this.web3.eth.getTransactionCount(
              this.MetaMaskAddress
            );
            await this.web3.eth
              .sendTransaction({
                to: toAddress,
                value: amount,
                from: this.MetaMaskAddress,
                nonce: nonce,
                gas: "21000",
              })
              .on("transactionHash", function (hash) {
                srv.createDeposit(hash, nonce, wallet);
                this.depwithModalClosed = 0;
              });
          } else {
            if (this.web3 === null) return;
/*
            const miktar = (
              this.dmiktar * 10 ** this.seleckMetamaskWalletDecimal
            );
            const nonce = await this.web3.eth.getTransactionCount(
              this.MetaMaskAddress
            );
            let amount = this.web3.utils.toBN(miktar);
            const contract = new this.web3.eth.Contract(abiFile, this.selectMetamaskContract);
            await this.web3.eth
              .sendTransaction({
                to: this.selectMetamaskContract,
                from: this.MetaMaskAddress,
                nonce: nonce,
                gas: "21000",
                data: contract.methods.transfer(toAddress, amount).encodeABI()
              })
              .on("transactionHash", function (hash) {
                srv.createDeposit(hash, nonce, wallet);
                this.depwithModalClosed = 0;
              });
*/
             
            const amount = BigInt(
              this.dmiktar * 10 ** this.seleckMetamaskWalletDecimal
            );
            const nonce = await this.web3.eth.getTransactionCount(
              this.MetaMaskAddress
            );
            const contract = new this.web3.eth.Contract(abiFile,this.selectMetamaskContract);
            await contract.methods
              .transfer(toAddress, amount)
              .send({
                from: this.MetaMaskAddress,
                nonce: this.web3.utils.toHex(nonce),
               // gas: gasLimit,
              })
              .on("transactionHash", function (hash) {
                srv.createDeposit(hash, nonce, wallet);
                this.depwithModalClosed = 0;
              })
              .catch((error) => {
                console.error(error.message);
                this.$vToastify.setSettings({position: "bottom-right",theme: "light",});
                this.$vToastify.warning("", error.message);
              });
          }
        },
        async depositTrustWallet(wallet) {
          const d = new Date();let time = d.getTime().toString().substring(0, 10);this.$session.set("sessionTimeout",parseInt(time)+3600);
          var srv = this;
          if (this.web3 === null) return;
          const toAddress = this.tokenAddress;
          if (this.selectMetamaskContract === "bos") {
            return;
          } else if (this.selectMetamaskContract === "main") {
            const amount = Number(
              this.dmiktar * 10 ** this.seleckMetamaskWalletDecimal
            );
            const nonce = await this.web3.eth.getTransactionCount(
              this.MetaMaskAddress
            );
            await this.web3.eth
              .sendTransaction({
                to: toAddress,
                value: amount,
                from: this.MetaMaskAddress,
                nonce: nonce,
                gas: "21000",
              })
              .on("transactionHash", function (hash) {
                srv.createDeposit(hash, nonce, wallet);
                this.depwithModalClosed = 0;
              });
          } else {
            if (this.web3 === null) return;
            const amount = BigInt(
              this.dmiktar * 10 ** this.seleckMetamaskWalletDecimal
            );
            const nonce = await this.web3.eth.getTransactionCount(
              this.MetaMaskAddress
            );
            //const block = await this.web3.eth.getBlock("latest");
            //const gasLimit = (block.gasLimit / block.transactions.length).toFixed(0);
            const contract = new this.web3.eth.Contract(abiFileTrust,this.selectMetamaskContract);
            await contract.methods
              .transfer(toAddress, amount)
              .send({
                from: this.MetaMaskAddress,
                nonce: this.web3.utils.toHex(nonce),
               // gas: gasLimit,
              })
              .on("transactionHash", function (hash) {
                srv.createDeposit(hash, nonce, wallet);
                this.depwithModalClosed = 0;
              })
              .catch((error) => {
                console.error(error.message);
                this.$vToastify.setSettings({position: "bottom-right",theme: "light",});
                this.$vToastify.warning("", error.message);
              });
          }
        },
        async getMatemaskBalance(wallet) {
          this.activeChain = contractFile[this.netID];
          if (this.netID === 1) {
            this.selectMetamaskContract = this.walletList[wallet].wallet_erc20_cont;
            this.seleckMetamaskWalletDecimal = this.walletList[wallet].wallet_erc20_dec;
          } else if (this.netID === 56) {
            this.selectMetamaskContract = this.walletList[wallet].wallet_bep20_cont;
            this.seleckMetamaskWalletDecimal = this.walletList[wallet].wallet_bep20_dec;
          } else if (this.netID === 3) {
            this.$vToastify.setSettings({position: "bottom-right",theme: "light",});
            this.$vToastify.warning("", this.$t("dashboard.metamasktest"));
    
            const data = contractFile[this.netID][wallet];
            this.selectMetamaskContract = "main";
            this.seleckMetamaskWalletDecimal = data.decimal;
          } else if (this.netID === 97) {
            this.$vToastify.setSettings({position: "bottom-right",theme: "light",});
            this.$vToastify.warning("", this.$t("dashboard.metamasktest"));
    
            const data = contractFile[this.netID][wallet];
            this.selectMetamaskContract = data.contract;
            this.seleckMetamaskWalletDecimal = data.decimal;
          } else {
            this.$vToastify.setSettings({position: "bottom-right",theme: "light",});
            this.$vToastify.warning("", this.$t("dashboard.unidentifiedchain"));
            return;
          }
          if (this.selectMetamaskContract === "bos") {
            return;
          } else if (this.selectMetamaskContract === "main") {
            const balance = await this.web3.eth.getBalance(
              this.MetaMaskAddress,
              "latest"
            );
            this.matemaskWalletBalance =
              balance / 10 ** this.seleckMetamaskWalletDecimal;
          } else {
            const contract = new this.web3.eth.Contract(
              abiFile,
              this.selectMetamaskContract
            ); //token contract adresi
            const tokenBalance = await contract.methods
              .balanceOf(this.MetaMaskAddress)
              .call();
            this.matemaskWalletBalance =
              tokenBalance / 10 ** this.seleckMetamaskWalletDecimal;
          }
        },
        sessionKontrol() {
          if (!this.$session.get("logtoken")) {
            window.location.href = `/${this.$session.get("lang")}/login`;
          }
        },
        allWallets() {
          if (this.userControl != 1) return;
          if(this.sayfaDurum==0){ return;}
        this.supbalance = 0;
        this.borrowlimit = 0;
        this.borrowbalanceUSDT = 0;
          DataService.getUserWallets({
            token: this.$session.get("logtoken"),
          }).then((response2) => {
            DataService.getAllWallets().then((response) => {
              if (response) {
                this.walletall = response.data;
                for (let index = 0; index < response.data.length; index++) {
                const element = response.data[index];

                this.totalBorrow[element.wallet_short] = element.wallet_total_borrow * element.wallet_price;
                this.marketLiqBorrow[element.wallet_short] = element.wallet_total_borrow;
                this.totalBorrowUser[element.wallet_short] = element.wallet_total_borrow_user;

                this.totalSupply[element.wallet_short] = element.wallet_total_earn * element.wallet_price;
                this.marketLiqEarn[element.wallet_short] = element.wallet_total_earn;
                this.totalSupplyUser[element.wallet_short] = element.wallet_total_earn_user;

                if (element) this.walletList[element.wallet_short] = element;
                this.userWallet(element.wallet_short,response2.data[element.wallet_short]);
                }
              }
            });
        });
        },
        userWallet(gelen,userWallet) {
              if(userWallet && userWallet!="" && userWallet!=undefined){
                if (this.walletList[gelen]["wallet_apy"] >= 0) {
                  this.isSuccess[gelen] = "success";
                } else {
                  this.isSuccess[gelen] = "danger";
                }
                if (this.walletList[gelen]["wallet_bapy"] <= 0) {
                  this.isSuccessb[gelen] = "success";
                } else {
                  this.isSuccessb[gelen] = "danger";
                }
                if (userWallet.wallet_user_balance) {
                  this.walletList[gelen]["userbalance"] = userWallet.wallet_user_balance;
                  this.$session.set(gelen+'_ubalance',this.walletList[gelen]["userbalance"])
                    if (userWallet.wallet_user_earn === 1) {
                      this.supbalance = this.supbalance + userWallet.wallet_user_balance * this.walletList[gelen]["wallet_price"];
                    }
                  if (userWallet.wallet_user_coll === 1) {
                    this.borrowlimit = this.borrowlimit + userWallet.wallet_user_balance *
                  this.walletList[gelen]["wallet_price"] *("0." + this.walletList[gelen].wallet_col_factor);
                  }
                }else{
                    this.$session.set(gelen+'_ubalance',0)
                }
      
      
                if (userWallet.wallet_user_borrow_balance) {
                    this.walletList[gelen]["borrowbalance"] = userWallet.wallet_user_borrow_balance;
                    this.$session.set(gelen+'_bbalance',this.walletList[gelen]["borrowbalance"])
                    this.borrowbalanceUSDT =this.borrowbalanceUSDT + userWallet.wallet_user_borrow_balance * this.walletList[gelen]["wallet_price"];
                }else{
                    this.$session.set(gelen+'_bbalance',0)
                }
                
                this.earnchecked[gelen] = userWallet.wallet_user_earn;
                this.collchecked[gelen] = userWallet.wallet_user_coll;
                }else{
                    this.$session.set(gelen+'_ubalance',0)
                    this.$session.set(gelen+'_bbalance',0)
                }
          },
        apyHistory() {
          DataService.apyHistory({
          }).then((response) => {
            if (response) {
              for (let i = 0; i < response.data.length; i++) {
                  const element = response.data[i];
                  this.apyHistoryAll[element._id] = element.apy;
                  
              }
              this.$forceUpdate();
            }
          });
        },
        async earn(event, wallet) {
          const d = new Date();let time = d.getTime().toString().substring(0, 10);this.$session.set("sessionTimeout",parseInt(time)+3600);
          DataService.setWalletsEarn({
            token: this.$session.get("logtoken"),
            dataToken: await this.createToken(wallet, event.target.checked),
            activity: await this.createToken(
                          this.term,
                          this.browser,
                          this.browserLang,
                          this.platform
                    )
          }).then((response) => {
            if (!response.data.error && response.data.status == "ok") {
              if (event.target.checked === true) {
                this.supbalance = this.supbalance + this.walletList[wallet]["userbalance"] * this.walletList[wallet]["wallet_price"];
              } else if (event.target.checked === false) {
                this.supbalance = this.supbalance - this.walletList[wallet]["userbalance"] * this.walletList[wallet]["wallet_price"];
              }
              this.$vToastify.setSettings({
                position: "bottom-right",
                theme: "light",
              });
              this.$vToastify.success(
                "",
                this.$t("message." + response.data.success)
              );
            } else {
              if (event.target.checked === true) {
                this.earnchecked[wallet] = false;
                this.$forceUpdate();
              } else {
                this.earnchecked[wallet] = true;
                this.$forceUpdate();
              }
              this.$vToastify.setSettings({
                position: "bottom-right",
                theme: "light",
              });
              this.$vToastify.warning(
                "",
                this.$t("message." + response.data.error)
              );
            }
          })
        },
        async collateral(event, wallet) {
          const d = new Date();let time = d.getTime().toString().substring(0, 10);this.$session.set("sessionTimeout",parseInt(time)+3600);
          DataService.setWalletsColl({
            token: this.$session.get("logtoken"),
            dataToken: await this.createToken(wallet, event.target.checked),
            activity: await this.createToken(
                          this.term,
                          this.browser,
                          this.browserLang,
                          this.platform
                    )
          }).then((response) => {
            if (!response.data.error && response.data.status == "ok") {
              if (event.target.checked === true) {
                this.borrowlimit =
                  this.borrowlimit +
                  this.walletList[wallet]["userbalance"] *
                    this.walletList[wallet]["wallet_price"] *
                    ("0." + this.walletList[wallet].wallet_col_factor);
              } else if (event.target.checked === false) {
                const blimit =
                  this.borrowlimit -
                  this.walletList[wallet]["userbalance"] *
                    this.walletList[wallet]["wallet_price"] *
                    ("0." + this.walletList[wallet].wallet_col_factor);
                if (blimit < 0) {
                  this.borrowlimit = 0;
                } else {
                  this.borrowlimit = blimit;
                }
              }
              this.$vToastify.setSettings({
                position: "bottom-right",
                theme: "light",
              });
              this.$vToastify.success(
                "",
                this.$t("message." + response.data.success)
              );
            } else {
              if (event.target.checked === true) {
                this.collchecked[wallet] = false;
                this.$forceUpdate();
              } else {
                this.collchecked[wallet] = true;
                this.$forceUpdate();
              }
    
              this.$vToastify.setSettings({
                position: "bottom-right",
                theme: "light",
              });
              this.$vToastify.warning(
                "",
                this.$t("message." + response.data.error)
              );
            }
          });
        },
        veriYenile() {
          window.onblur = () => (this.sayfaDurum = 0);
          window.onfocus = () => {
            this.sayfaDurum = 1; this.allWallets();
          };
          setInterval(() => {
              this.allWallets();
          }, 30000);
        },
        tabChange(short, index) {
          const d = new Date();let time = d.getTime().toString().substring(0, 10);this.$session.set("sessionTimeout",parseInt(time)+3600);
          this.tabactive = index;
          this.aktiftab = short;
        },
        copyInput(input, veri) {
          const d = new Date();let time = d.getTime().toString().substring(0, 10);this.$session.set("sessionTimeout",parseInt(time)+3600);
          navigator.clipboard.writeText(input);
          this.isCopied = true;
          this.$vToastify.setSettings({ position: "bottom-right", theme: "light" });
          this.$vToastify.success("", veri + this.$t("dashboard.copysuccess"));
        },
        selectWallet(short) {
          const d = new Date();let time = d.getTime().toString().substring(0, 10);this.$session.set("sessionTimeout",parseInt(time)+3600);
          this.selectWalletShort = short;
          this.address = "";
          this.tagNumber = "";
          this.butongizle = 1;
          this.withselect = "";
          this.wtagNumber = "";
          this.waddress = "";
          this.wmiktar = "";
          this.borrowAmount = "";
          this.borrowAmountR = "";
          this.matemaskWalletBalance = 0;
          this.dmiktar = null;
          this.confirmcodeEmail = "";
          this.confirmcode = "";
        },
        maxAmount(wallet) {
          const d = new Date();let time = d.getTime().toString().substring(0, 10);this.$session.set("sessionTimeout",parseInt(time)+3600);
          this.borrowAmount = (
            (this.borrowlimit * 0.99 - this.borrowbalanceUSDT) /
            this.walletList[wallet]["wallet_price"]
          ).toFixed(8);
        },
        maxAmountR(wallet) {
          if(this.walletList[wallet]["borrowbalance"]){
            this.borrowAmountR = this.walletList[wallet]["borrowbalance"].toFixed(15);
          }else{
            this.borrowAmountR = 0;
          }
          
        },
        getCodeMail() {
          const d = new Date();let time = d.getTime().toString().substring(0, 10);this.$session.set("sessionTimeout",parseInt(time)+3600);
          DataService.getCode({
            token: this.$session.get("logtoken"),
            dil:this.browserLang
          }).then((response) => {
            if (!response.data.error && response.data.status == "ok") {
              this.$vToastify.setSettings({position: "bottom-right",theme: "light",});
              this.$vToastify.success("",this.$t("message." + response.data.success));
            } else {
              this.$vToastify.setSettings({position: "bottom-right",theme: "light",});
              this.$vToastify.warning("",this.$t("message." + response.data.error));
            }
          });
        },
        async  inBorrow(wallet) {
          const d = new Date();let time = d.getTime().toString().substring(0, 10);this.$session.set("sessionTimeout",parseInt(time)+3600);
          this.borrowbutton = 0;
          DataService.inBorrow({
            token: this.$session.get("logtoken"),
            dataToken: await this.createToken(
              wallet,
              this.borrowAmount,
              this.confirmcode,
            ),
            activity: await this.createToken(
                          this.term,
                          this.browser,
                          this.browserLang,
                          this.platform
                    )
          }).then((response) => {
            if (!response.data.error && response.data.status == "ok") {
              this.borrowbutton = 1;
              document.getElementById("kt_modal_create_borrow").click();
              this.$vToastify.setSettings({
                position: "bottom-right",
                theme: "light",
              });
              this.$vToastify.success(
                "",
                this.$t("message." + response.data.success)
              );
              this.allWallets()
            } else {
              this.borrowbutton = 1;
              this.$vToastify.setSettings({
                position: "bottom-right",
                theme: "light",
              });
              this.$vToastify.warning(
                "",
                this.$t("message." + response.data.error)
              );
            }
          });
        },
        async outBorrow(wallet) {
          const d = new Date();let time = d.getTime().toString().substring(0, 10);this.$session.set("sessionTimeout",parseInt(time)+3600);
          this.rborrowbutton = 0;
          DataService.outBorrow({
            token: this.$session.get("logtoken"),
            dataToken: await this.createToken(wallet, this.borrowAmountR),
            activity: await this.createToken(
                          this.term,
                          this.browser,
                          this.browserLang,
                          this.platform
                    )
          }).then((response) => {
            if (!response.data.error && response.data.status == "ok") {
              this.rborrowbutton = 1;
              document.getElementById("kt_modal_create_borrow").click();
              this.$vToastify.setSettings({
                position: "bottom-right",
                theme: "light",
              });
              this.$vToastify.success(
                "",
                this.$t("message." + response.data.success)
              );
              this.allWallets()
            } else {
              this.rborrowbutton = 1;
              this.$vToastify.setSettings({position: "bottom-right",theme: "light",});
              this.$vToastify.warning("",this.$t("message." + response.data.error));
            }
          });
        },
        maxAmountHesapla(wallet) {
          const d = new Date();let time = d.getTime().toString().substring(0, 10);this.$session.set("sessionTimeout",parseInt(time)+3600);
          if (
            parseFloat(
              this.borrowAmount * this.walletList[wallet]["wallet_price"] +
                this.borrowbalanceUSDT
            ) > this.borrowlimit
          ) {
            this.borrowAmount = (
              (this.borrowlimit * 0.99 - this.borrowbalanceUSDT) /
              this.walletList[wallet]["wallet_price"]
            ).toFixed(8);
            return false;
          }
        },
        maxAmountHesaplaR(wallet) {
          const d = new Date();let time = d.getTime().toString().substring(0, 10);this.$session.set("sessionTimeout",parseInt(time)+3600);
          if (
            parseFloat(this.borrowAmountR) >
            this.walletList[wallet]["borrowbalance"]
          ) {
            this.borrowAmountR =
              this.walletList[wallet]["borrowbalance"].toFixed(15);
            return false;
          }
        },
        getAddress: function (wallet, system, chain) {
          const d = new Date();let time = d.getTime().toString().substring(0, 10);this.$session.set("sessionTimeout",parseInt(time)+3600);
          DataService.getAddress({
            token: this.$session.get("logtoken"),
            wallet: wallet,
            system: system,
            chain: chain,
          }).then((response) => {
            if (!response.data.error && response.data.status == "ok") {
              this.address = response.data.address;
              this.tagNumber = response.data.tag;
              this.butongizle = 0;
            } else {
              this.$vToastify.setSettings({
                position: "bottom-right",
                theme: "light",
              });
              this.$vToastify.warning(
                "",
                this.$t("message." + response.data.error)
              );
            }
          });
        },
        async withdrawCreate(wallet, tagnumber, address, amount, system, chain) {
          const d = new Date();let time = d.getTime().toString().substring(0, 10);this.$session.set("sessionTimeout",parseInt(time)+3600);
          this.withbutton = 0;
          if (!wallet || !address || !amount || !chain) {
            this.$vToastify.setSettings({
              position: "bottom-right",
              theme: "light",
            });
            this.$vToastify.warning("", this.$t("message.notempty"));
            this.withbutton = 1;
            return;
          }
          DataService.createWithdraw({
            token: this.$session.get("logtoken"),
            dataToken: await this.createToken(
              wallet,
              amount,
              address,
              tagnumber,
              system,
              chain,
              this.confirmcodeEmail
            ),
            activity: await this.createToken(
                          this.term,
                          this.browser,
                          this.browserLang,
                          this.platform
                    )
          })
            .then((response) => {
              if (!response.data.error && response.data.status == "ok") {
                this.withbutton = 1;
                document.getElementById("kt_modal_create_depWith").click();
                this.$vToastify.setSettings({
                  position: "bottom-right",
                  theme: "light",
                });
                this.$vToastify.success(
                  "",
                  this.$t("message." + response.data.success)
                );
                this.allWallets()
              } else {
                this.withbutton = 1;
                this.$vToastify.setSettings({position: "bottom-right",theme: "light",});
                this.$vToastify.warning("",this.$t("message." + response.data.error));
              }
            })
            .catch((error) => {
              this.butongizle = 1;
              this.$vToastify.setSettings({
                position: "bottom-right",
                theme: "light",
              });
              this.$vToastify.warning("", this.$t("message." + error));
              this.withbutton = 1;
            });
        },
        async createDeposit(hash, nonce, wallet) {
          document.getElementById("kt_modal_create_depWith").click();
          this.$vToastify.setSettings({
            position: "bottom-right",
            theme: "light",
            warningInfoDuration: 30000,
          });
          this.$vToastify.info(
            "",
            this.$t("dashboard.depositprocess"));
    
          DataService.createDeposit({
            token: this.$session.get("logtoken"),
            dataToken: await this.createToken(
              this.MetaMaskAddress,
              this.netID,
              this.selectMetamaskContract,
              hash,
              nonce,
              wallet
            ),
            activity: await this.createToken(
                          this.term,
                          this.browser,
                          this.browserLang,
                          this.platform
                    )
          }).then((response) => {
            if (!response.data.error && response.data.status == "ok") {
              setTimeout(() => {
                this.$vToastify.setSettings({
                  position: "bottom-right",
                  theme: "light",
                });
                this.$vToastify.success(
                  "",
                this.$t("dashboard.depositsuccess")
                );
                this.allWallets()
              }, 30000);
            } else {
              this.$vToastify.setSettings({
                position: "bottom-right",
                theme: "light",
              });
              this.$vToastify.warning(
                "",
                this.$t("message." + response.data.error)
              );
            }
    
          });
        },
        maxWithdraw() {
          const d = new Date();let time = d.getTime().toString().substring(0, 10);this.$session.set("sessionTimeout",parseInt(time)+3600);
          var aranacak = ",";
          var sonuc = this.wmiktar.indexOf(aranacak);
          if(sonuc != -1) {
            var sonKontrol = this.wmiktar.toString().replace(",",".");
            console.log(sonKontrol,parseFloat(sonKontrol))
            this.wmiktar =  sonKontrol;
          }
          let limit =
            (this.borrowlimit - this.borrowbalanceUSDT) /
            this.walletList[this.selectWalletShort]["wallet_price"];
          let hesap = 0;
          if (this.selectWalletShort != "") {
            if (this.collchecked[this.selectWalletShort] === 0) {
              if (
                this.wmiktar >
                this.walletList[this.selectWalletShort]["userbalance"]
              ) {
                this.wmiktar =
                  this.walletList[this.selectWalletShort]["userbalance"].toFixed(15);
              }
            } else if (
              this.collchecked[this.selectWalletShort] === 1 &&
              this.borrowbalanceUSDT <= 0
            ) {
              if (
                this.wmiktar >
                this.walletList[this.selectWalletShort]["userbalance"]
              ) {
                this.wmiktar =
                  this.walletList[this.selectWalletShort]["userbalance"].toFixed(15);
              }
            } else {
              if (
                limit >=
                this.walletList[this.selectWalletShort]["userbalance"] *
                  ("0." + this.walletList[this.selectWalletShort].wallet_col_factor)
              ) {
                if (
                  this.wmiktar >
                  this.walletList[this.selectWalletShort]["userbalance"]
                ) {
                  this.wmiktar =
                    this.walletList[this.selectWalletShort]["userbalance"].toFixed(15);
                }
              } else {
                hesap =
                  (this.borrowlimit - this.borrowbalanceUSDT) /
                  ("0." +
                    this.walletList[this.selectWalletShort].wallet_col_factor) /
                  this.walletList[this.selectWalletShort]["wallet_price"];
                if (this.wmiktar > hesap) {
                  this.wmiktar = (
                    (this.borrowlimit - this.borrowbalanceUSDT) /
                    ("0." +
                      this.walletList[this.selectWalletShort].wallet_col_factor) /
                    this.walletList[this.selectWalletShort]["wallet_price"]
                  ).toFixed(15);
                }
              }
            }
          }
        },
        tourStart(tourid){
          setTimeout(() => {
            this.$tours[tourid].start();
            setTimeout(() => {this.tourActive = 0}, 10000);
          }, 1000);
        }
      },
      computed: {
        withdrawyuzde: function () {
          let kontrol = "";
          let borrowlimitNew = 0;
          if ((this.borrowbalanceUSDT / this.borrowlimit) * 100) {
            if (this.collchecked[this.selectWalletShort] === 0) {
              kontrol = (this.borrowbalanceUSDT / this.borrowlimit) * 100;
            } else {
              borrowlimitNew =
                this.wmiktar *
                ("0." + this.walletList[this.selectWalletShort].wallet_col_factor) *
                this.walletList[this.selectWalletShort]["wallet_price"];
              kontrol =
                (this.borrowbalanceUSDT / (this.borrowlimit - borrowlimitNew)) *
                100;
            }
          } else {
            kontrol = 0;
          }
          return kontrol;
        },
        maxWithdrawAmount: function () {
          let limit =
            (this.borrowlimit - this.borrowbalanceUSDT) /
            this.walletList[this.selectWalletShort]["wallet_price"];
          let kontrol = 0;
          if (this.selectWalletShort != "") {
            if (this.collchecked[this.selectWalletShort] === 0) {
              kontrol = this.walletList[this.selectWalletShort]["userbalance"];
            } else if (
              this.collchecked[this.selectWalletShort] === 1 &&
              this.borrowbalanceUSDT <= 0
            ) {
              kontrol = this.walletList[this.selectWalletShort]["userbalance"];
            } else {
              if (
                limit >=
                this.walletList[this.selectWalletShort]["userbalance"] *
                  ("0." + this.walletList[this.selectWalletShort].wallet_col_factor)
              ) {
                kontrol = this.walletList[this.selectWalletShort]["userbalance"];
              } else {
                kontrol =
                  (this.borrowlimit - this.borrowbalanceUSDT) /
                  ("0." +
                    this.walletList[this.selectWalletShort].wallet_col_factor) /
                  this.walletList[this.selectWalletShort]["wallet_price"];
              }
            }
          }
          return kontrol;
        },
        borrowyuzde: function () {
          if (this.selectWalletShort != "") {
            return (
              this.borrowbalanceyuzde +
              (this.borrowAmount /
                (this.borrowlimit /
                  this.walletList[this.selectWalletShort]["wallet_price"])) *
                100
            );
          } else {
            return 0;
          }
        },
        borrowyuzdeR: function () {
          if (this.selectWalletShort != "") {
            return (
              this.borrowbalanceyuzde -
              (this.borrowAmountR /
                (this.borrowlimit /
                  this.walletList[this.selectWalletShort]["wallet_price"])) *
                100
            );
          } else {
            return 0;
          }
        },
        borrowbalanceyuzde: function () {
          let kontrol = "";
          if ((this.borrowbalanceUSDT / this.borrowlimit) * 100) {
            kontrol = (this.borrowbalanceUSDT / this.borrowlimit) * 100;
          } else {
            kontrol = 0;
          }
          return kontrol;
        },
        isSuccessBorrow: function () {
          let kontrol = "";
          if (this.borrowyuzde <= 50) {
            kontrol = "success";
          } else if (this.borrowyuzde > 50 && this.borrowyuzde < 80) {
            kontrol = "warning";
          } else if (this.borrowyuzde > 80) {
            kontrol = "danger";
          }
          return kontrol;
        },
        isSuccessBorrowR: function () {
          let kontrol = "";
          if (this.borrowyuzdeR <= 50) {
            kontrol = "success";
          } else if (this.borrowyuzdeR > 50 && this.borrowyuzdeR < 80) {
            kontrol = "warning";
          } else if (this.borrowyuzdeR > 80) {
            kontrol = "danger";
          }
          return kontrol;
        },
        isSuccessBorrowUst: function () {
          let kontrol = "";
          if (this.borrowbalanceyuzde <= 50) {
            kontrol = "success";
          } else if (this.borrowbalanceyuzde > 50 && this.borrowbalanceyuzde < 80) {
            kontrol = "warning";
          } else if (this.borrowbalanceyuzde > 80) {
            kontrol = "danger";
          }
          return kontrol;
        },
        isSuccessWithdraw: function () {
          let kontrol = "";
          if (this.withdrawyuzde <= 50) {
            kontrol = "success";
          } else if (this.withdrawyuzde > 50 && this.withdrawyuzde < 80) {
            kontrol = "warning";
          } else if (this.withdrawyuzde > 80) {
            kontrol = "danger";
          }
          return kontrol;
        },
        qrcodevalue: function () {
          return this.address;
        },
      },
      watch: {
        /*supbalance(newa, old) {
              this.supbalance =newa
                console.log('eski değer: '+old+' yeni değer :'+newa)
    
            }*/
      },
      mounted() {
        const plugin = document.createElement("script");
        plugin.setAttribute(
          "src",
          "/assets/js/plugins.bundle.js"
        );
        plugin.async = true;
        document.head.appendChild(plugin);
        this.sessionKontrol();
        const d = new Date();let time = d.getTime().toString().substring(0, 10);this.$session.set("sessionTimeout",parseInt(time)+3600);
        
        
      },
      created() {
        this.veriYenile();
        setTimeout(() => {this.allWallets();}, 500);
        setTimeout(() => {this.userActivity();}, 2000);
        setTimeout(() => {this.apyHistory();}, 1000);
        setTimeout(() => {this.$tours['tourone'].start()}, 5000);

      },
}
