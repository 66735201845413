<template>
  <div>
    <Dashboard></Dashboard>
  </div>
</template>

<script>
// @ is an alias to /src
import Dashboard from '@/components/Dashboard.vue'

export default {
  name: 'Dasboard',
  components: {
    Dashboard,
  }
}
</script>
