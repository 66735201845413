<template>
  <div>
    <div class="d-flex flex-column flex-root">
      <!--begin::Page-->
      <div class="page d-flex flex-row flex-column-fluid">
        <Asidemenu></Asidemenu>
        <!--begin::Wrapper-->
        <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header></Header>
          <!--<button class="btn btn-primary" type="button" @click="wavesTest">waves</button>-->

          <!--begin::Content-->
          <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
            <!--begin::Post-->
            <div class="post d-flex flex-column-fluid" id="kt_post">
              <!--begin::Container-->
              <div id="kt_content_container" class="container-xxl">
                <!--begin::Navbar-->
                <div class="card mb-5">
                  <div class="card-body pt-9 pb-0">
                    <!--begin::Details-->
                    <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
                      <!--begin::Info-->
                      <div class="flex-grow-1">
                        <!--begin::Stats-->
                        <div class="d-flex flex-wrap flex-stack">
                          <!--begin::Wrapper-->
                          <div class="d-flex flex-column flex-grow-1 pe-8">
                            <!--begin::Stats-->
                            <div class="d-flex flex-wrap">
                              <!--begin::Stat-->
                              <div class="
                                  border border-gray-300 border-dashed
                                  rounded
                                  min-w-125px
                                  py-3
                                  px-4
                                  me-4
                                  mb-3
                                ">
                                <!--begin::Number-->
                                <div class="d-flex align-items-center">
                                  <!--begin::Svg Icon | path: icons/duotune/arrows/arr066.svg-->
                                  <span class="svg-icon svg-icon-3 svg-icon-success">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                      fill="none">
                                      <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1"
                                        transform="rotate(90 13 6)" fill="black" />
                                      <path
                                        d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                        fill="black" />
                                    </svg>
                                  </span>
                                  <!--end::Svg Icon-->
                                  <div class="fs-2 fw-bolder w-140px" data-kt-countup="true"
                                    :data-kt-countup-value="supbalance" data-kt-countup-prefix="$"
                                    data-kt-countup-decimal-places="2">
                                    0
                                  </div>
                                </div>
                                <!--end::Number-->
                                <!--begin::Label-->
                                <div class="fw-bold fs-6 text-gray-400">
                                  {{ this.$t("dashboard.supplybalance") }}
                                </div>
                                <!--end::Label-->
                              </div>
                              <div class="
                                  border border-gray-300 border-dashed
                                  rounded
                                  min-w-125px
                                  py-3
                                  px-4
                                  me-4
                                  mb-3
                                ">
                                <!--begin::Number-->
                                <div class="d-flex align-items-center">
                                  <!--begin::Svg Icon | path: icons/duotune/arrows/arr066.svg-->
                                  <span class="svg-icon svg-icon-3 svg-icon-success">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                      fill="none">
                                      <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1"
                                        transform="rotate(90 13 6)" fill="black" />
                                      <path
                                        d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                        fill="black" />
                                    </svg>
                                  </span>
                                  <!--end::Svg Icon-->
                                  <div class="fs-2 fw-bolder w-140px" data-kt-countup="true"
                                    :data-kt-countup-value="`${borrowbalanceUSDT}`" data-kt-countup-prefix="$"
                                    data-kt-countup-decimal-places="2">
                                    0
                                  </div>
                                </div>
                                <!--end::Number-->
                                <!--begin::Label-->
                                <div class="fw-bold fs-6 text-gray-400">
                                  {{ $t("dashboard.borrowbalance") }}
                                </div>
                                <!--end::Label-->
                              </div>
                              <!--end::Stat-->
                            </div>
                            <!--end::Stats-->
                          </div>
                          <!--end::Wrapper-->
                          <!--begin::Progress-->
                          <div class="
                              d-flex
                              align-items-center
                              w-200px w-sm-300px
                              flex-column
                              mt-3
                            ">
                            <div class="
                                d-flex
                                justify-content-between
                                w-100
                                mt-auto
                                mb-2
                              ">
                              <span class="fw-bold fs-6 text-gray-400">{{
                                this.$t("dashboard.availablecredit")
                                }}</span>
                              <span class="fw-bolder fs-6 fw-bolder" data-kt-countup="true"
                                :data-kt-countup-value="borrowlimit" data-kt-countup-prefix="$"
                                data-kt-countup-decimal-places="2">0</span>
                            </div>
                            <div class="
                                d-flex
                                justify-content-between
                                w-100
                                mt-auto
                                mb-2
                              ">
                              <span class="fw-bold fs-6 text-gray-400">{{
                                $t("dashboard.borrowlimit")
                                }}</span>
                              <span class="fw-bolder fs-6">{{ borrowbalanceyuzde.toFixed(2) }}%</span>
                            </div>
                            <div class="h-5px mx-3 w-100 bg-light mb-3">
                              <div :class="`bg-${isSuccessBorrowUst} rounded h-5px`" role="progressbar"
                                :style="`width: ${borrowbalanceyuzde}%;`" aria-valuenow="50" aria-valuemin="0"
                                aria-valuemax="100"></div>
                            </div>
                          </div>
                          <!--end::Progress-->
                        </div>
                        <!--end::Stats-->
                      </div>
                      <!--end::Info-->
                    </div>
                    <!--end::Details-->
                  </div>
                </div>
                <!--end::Navbar-->
                <!--begin::Row-->
                <div class="row gy-5 g-xl-8">
                  <div class="col-xl-5">
                    <!--begin::Tables Widget 5-->
                    <div class="card card-xl-stretch mb-5">
                      <!--begin::Header-->
                      <div class="card-header border-0 pt-5">
                        <h3 class="card-title align-items-start">
                          <span class="card-label fw-bolder fs-3 mb-1">{{
                            $t("dashboard.overview")
                            }}</span>
                        </h3>
                        <div class="card-toolbar">
                          <ul class="nav" v-for="(swallet, index) in walletall" :key="index">
                            <li class="nav-item">
                              <a @click="tabChange(swallet.wallet_short, index)" class="
                                  nav-link
                                  btn
                                  btn-sm
                                  btn-color-muted
                                  btn-active
                                  btn-active-dark
                                  fw-bolder
                                  px-4
                                  me-1
                                " :class="{ active: tabactive === index }" data-bs-toggle="tab">{{ swallet.wallet_short
                                }}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <!--end::Header-->
                      <!--begin::Body-->
                      <div class="card-body py-3">
                        <div class="tab-content">
                          <!--begin::Tap pane-->
                          <div class="tab-pane fade show active" id="kt_table_widget_5_tab_1">
                            <div class="card card-xl-stretch-50 mb-5 mb-xl-8">
                              <!--begin::Body-->
                              <div class="card-body d-flex flex-column p-0">
                                <!--begin::Stats-->
                                <div class="flex-grow-1 card-p pb-0">
                                  <div class="d-flex flex-stack flex-wrap">
                                    <div class="me-2">
                                      <a href="#" class="
                                          text-dark text-hover-primary
                                          fw-bolder
                                          fs-3
                                        ">{{ $t("dashboard.apyhistory") }}</a>
                                      <div class="text-muted fs-7 fw-bold"></div>
                                    </div>
                                    <div class="fw-bolder fs-3 text-primary" v-if="walletList[aktiftab]">
                                      {{ walletList[aktiftab].wallet_apy }}%
                                      {{ $t("dashboard.supplyapy") }}
                                    </div>
                                  </div>
                                </div>
                                <!--end::Stats-->
                                <!--begin::Chart-->
                                <div class="
                                    mixed-widget-7-chart
                                    card-rounded-bottom
                                  " data-kt-chart-color="primary" :apy-1="
                                    apyHistoryAll[aktiftab]
                                      ? apyHistoryAll[aktiftab][5]
                                      : [0, 0, 0, 0, 0, 0]
                                  " :apy-2="
                                    apyHistoryAll[aktiftab]
                                      ? apyHistoryAll[aktiftab][4]
                                      : [0, 0, 0, 0, 0, 0]
                                  " :apy-3="
                                    apyHistoryAll[aktiftab]
                                      ? apyHistoryAll[aktiftab][3]
                                      : [0, 0, 0, 0, 0, 0]
                                  " :apy-4="
                                    apyHistoryAll[aktiftab]
                                      ? apyHistoryAll[aktiftab][2]
                                      : [0, 0, 0, 0, 0, 0]
                                  " :apy-5="
                                    apyHistoryAll[aktiftab]
                                      ? apyHistoryAll[aktiftab][1]
                                      : [0, 0, 0, 0, 0, 0]
                                  " :apy-6="
                                    apyHistoryAll[aktiftab]
                                      ? apyHistoryAll[aktiftab][0]
                                      : [0, 0, 0, 0, 0, 0]
                                  " :wallet="aktiftab ? aktiftab : 'BTC'" style="height: 150px"></div>
                                <!--end::Chart-->
                              </div>
                              <!--end::Body-->
                            </div>
                            <!--begin::Card body-->
                            <div class="card-body p-2">
                              <!--begin::Row-->
                              <div class="row mb-7">
                                <!--begin::Label-->
                                <label class="col-lg-5 fw-bold text-muted">{{
                                  $t("dashboard.name")
                                  }}</label>
                                <!--end::Label-->
                                <!--begin::Col-->
                                <div class="col-lg-7" v-if="walletList[aktiftab]">
                                  <span class="fw-bolder fs-6 text-gray-800">{{
                                    walletList[aktiftab].wallet_name
                                    }}</span>
                                </div>
                                <!--end::Col-->
                              </div>
                              <div class="row mb-7">
                                <!--begin::Label-->
                                <label class="col-lg-5 fw-bold text-muted">{{
                                  $t("dashboard.price")
                                  }}</label>
                                <!--end::Label-->
                                <!--begin::Col-->
                                <div class="col-lg-7" v-if="walletList[aktiftab]">
                                  <span class="fw-bolder fs-6 text-gray-800">{{
                                    walletList[aktiftab].wallet_price.toFixed(
                                    5
                                    )
                                    }}
                                    USDT</span>
                                </div>
                                <!--end::Col-->
                              </div>
                              <!--end::Row-->
                              <!--begin::Input group-->
                              <div class="row mb-7">
                                <!--begin::Label-->
                                <label class="col-lg-5 fw-bold text-muted">{{
                                  $t("dashboard.marketliquidity")
                                  }}</label>
                                <!--end::Label-->
                                <!--begin::Col-->
                                <div class="col-lg-7 fv-row" v-if="walletList[aktiftab]">
                                  <span class="fw-bold text-gray-800 fs-6">{{
                                    marketLiqEarn[aktiftab]
                                    ? (
                                    marketLiqEarn[aktiftab] -
                                    marketLiqBorrow[aktiftab]
                                    ).toFixed(5)
                                    : 0
                                    }}
                                    {{ aktiftab }}</span>
                                </div>
                                <!--end::Col-->
                              </div>
                              <!--end::Input group-->
                              <!--begin::Input group-->
                              <div class="row mb-7">
                                <!--begin::Label-->
                                <label class="col-lg-5 fw-bold text-muted">{{
                                  $t("dashboard.suppliers")
                                  }}</label>
                                <!--end::Label-->
                                <!--begin::Col-->
                                <div class="col-lg-7 fv-row">
                                  <span class="fw-bold text-gray-800 fs-6">{{
                                    totalSupplyUser[aktiftab]
                                    }}</span>
                                </div>
                                <!--end::Col-->
                              </div>
                              <!--end::Input group-->
                              <!--begin::Input group-->
                              <div class="row mb-7">
                                <!--begin::Label-->
                                <label class="col-lg-5 fw-bold text-muted">{{
                                  $t("dashboard.borrowers")
                                  }}</label>
                                <!--end::Label-->
                                <!--begin::Col-->
                                <div class="col-lg-7 fv-row">
                                  <span class="fw-bold text-gray-800 fs-6">{{
                                    totalBorrowUser[aktiftab]
                                    }}</span>
                                </div>
                                <!--end::Col-->
                              </div>
                              <!--end::Input group-->
                              <!--begin::Input group-->
                              <div class="row mb-7">
                                <!--begin::Label-->
                                <label class="col-lg-5 fw-bold text-muted">{{
                                  $t("dashboard.collateralfactor")
                                  }}</label>
                                <!--end::Label-->
                                <!--begin::Col-->
                                <div class="col-lg-7 fv-row">
                                  <span class="fw-bold text-gray-800 fs-6" v-if="walletList[aktiftab]">{{
                                    walletList[aktiftab].wallet_col_factor
                                    }}%</span>
                                </div>
                                <!--end::Col-->
                              </div>
                              <!--end::Input group-->
                              <!--begin::Input group-->
                              <div class="row mb-7">
                                <!--begin::Label-->
                                <label class="col-lg-5 fw-bold text-muted">{{
                                  $t("dashboard.totalsupply")
                                  }}</label>
                                <!--end::Label-->
                                <!--begin::Col-->
                                <div class="col-lg-7 fv-row">
                                  <span class="fw-bold text-gray-800 fs-6">{{
                                    totalSupply[aktiftab]
                                    ? totalSupply[aktiftab].toFixed(2)
                                    : 0
                                    }}
                                    USDT</span>
                                </div>
                                <!--end::Col-->
                              </div>
                              <!--end::Input group-->
                              <!--begin::Input group-->
                              <div class="row mb-7">
                                <!--begin::Label-->
                                <label class="col-lg-5 fw-bold text-muted">{{
                                  $t("dashboard.totalborrow")
                                  }}</label>
                                <!--end::Label-->
                                <!--begin::Col-->
                                <div class="col-lg-7 fv-row">
                                  <span class="fw-bold text-gray-800 fs-6">{{
                                    totalBorrow[aktiftab]
                                    ? totalBorrow[aktiftab].toFixed(2)
                                    : 0
                                    }}
                                    USDT</span>
                                </div>
                                <!--end::Col-->
                              </div>
                              <!--end::Input group-->
                            </div>
                            <!--end::Card body-->
                          </div>
                        </div>
                      </div>
                      <!--end::Body-->
                    </div>
                    <!--end::Tables Widget 5-->
                  </div>
                  <div class="col-xl-7">
                    <!--begin::Tables Widget 5-->
                    <div class="card card-xl-stretch mb-5">
                      <!--begin::Header-->
                      <div class="card-header border-0 pt-5">
                        <div class="card-toolbar">
                          <ul class="nav">
                            <li class="nav-item">
                              <a class="
                                  nav-link
                                  btn
                                  btn-sm
                                  btn-color-muted
                                  btn-active
                                  btn-active-dark
                                  active
                                  fw-bolder
                                  px-4
                                  me-1
                                " data-bs-toggle="tab" href="#kt_table_widget_5_tab_1_sag">{{
                                $t("dashboard.supplymarket") }}</a>
                            </li>
                            <li class="nav-item">
                              <a class="
                                  nav-link
                                  btn
                                  btn-sm
                                  btn-color-muted
                                  btn-active
                                  btn-active-dark
                                  fw-bolder
                                  px-4
                                  me-1
                                " data-bs-toggle="tab" @click="tourStart('tourtwo')"
                                href="#kt_table_widget_5_tab_2_sag">{{ $t("dashboard.borrowmarket") }}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <!--end::Header-->
                      <!--begin::Body-->

                      <div class="card-body py-3">
                        <div class="tab-content">
                          <!--begin::Tap pane-->
                          <div class="tab-pane fade show active" id="kt_table_widget_5_tab_1_sag">
                            <!--begin::Table container-->
                            <div class="table-responsive">
                              <!--begin::Table-->
                              <table class="
                                  table table-row-dashed table-row-gray-200
                                  align-middle
                                  gs-0
                                  gy-4
                                ">
                                <!--begin::Table head-->
                                <thead>
                                  <tr class="border-0">
                                    <th class="p-2 min-w-100px">
                                      {{ $t("dashboard.asset") }}
                                    </th>
                                    <th class="p-2">APY</th>
                                    <th class="p-2 text-end">
                                      {{ $t("dashboard.balance") }}
                                    </th>
                                    <th class="p-2 min-w-75px text-center">
                                      {{ $t("dashboard.earn") }}
                                    </th>
                                    <th class="p-2 text-center">
                                      {{ $t("dashboard.collateral") }}
                                    </th>
                                  </tr>
                                </thead>
                                <!--end::Table head-->
                                <!--begin::Table body-->
                                <tbody>
                                  <tr v-for="(swallet, index) in walletall" :key="index">
                                    <td id="depwith-step" class="pulse pulse-primary">
                                      <v-tour v-if="swallet.wallet_short == 'BTC'" name="tourone" :options="myOptions"
                                        :steps="steps"></v-tour>
                                      <span class="pulse-ring ms-n1 border-2"></span>

                                      <a @click="
                                          selectWallet(swallet.wallet_short)
                                        " href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_create_depWith"
                                        class="
                                          text-dark
                                          fw-bolder
                                          text-hover-primary
                                          mb-1
                                          fs-6
                                        ">{{ swallet.wallet_short }}
                                      </a>
                                      <span class="text-muted fw-bold d-block">
                                        <a @click="
                                            selectWallet(swallet.wallet_short)
                                          " href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_create_depWith"
                                          class="
                                            text-muted
                                            fw-bolder
                                            text-hover-primary
                                          ">{{ swallet.wallet_name }}</a>
                                      </span>
                                    </td>
                                    <td class="text-start">
                                      <span :class="`badge-light-${
                                          isSuccess[swallet.wallet_short]
                                        }`">{{ swallet.wallet_apy }}%</span>
                                    </td>
                                    <td class="text-end text-muted fw-bold">
                                      {{
                                      parseFloat(
                                      $session.get(
                                      swallet.wallet_short + "_ubalance"
                                      )
                                      ).toFixed(8)
                                      }}
                                      <span class="text-muted fw-bold d-block">{{
                                        parseFloat(
                                        $session.get(
                                        swallet.wallet_short + "_ubalance"
                                        ) * swallet.wallet_price
                                        ).toFixed(2)
                                        }}
                                        USDT</span>
                                    </td>

                                    <td id="depwith-step-2" class="text-start">
                                      <label for="kt_user_menu_dark_mode_toggle" class="
                                          form-check
                                          form-switch
                                          form-check-custom
                                          form-check-solid
                                        ">
                                        <input type="checkbox" v-model="
                                            earnchecked[swallet.wallet_short]
                                          " @change="
                                            earn($event, swallet.wallet_short)
                                          " name="mode" id="kt_user_menu_dark_mode_toggle" data-kt-url="#"
                                          class="form-check-input w-30px h-20px m-auto" />
                                      </label>
                                    </td>
                                    <td id="depwith-step-3" class="text-start">
                                      <label for="kt_user_menu_dark_mode_toggle" class="
                                          form-check
                                          form-switch
                                          form-check-custom
                                          form-check-solid
                                        ">
                                        <input type="checkbox" v-model="
                                            collchecked[swallet.wallet_short]
                                          " @change="
                                            collateral(
                                              $event,
                                              swallet.wallet_short
                                            )
                                          " name="mode" id="kt_user_menu_dark_mode_toggle" data-kt-url="#"
                                          class="form-check-input w-30px h-20px m-auto" />
                                      </label>
                                    </td>
                                  </tr>
                                </tbody>
                                <!--end::Table body-->
                              </table>
                            </div>
                            <!--end::Table-->
                          </div>
                          <div class="tab-pane fade" id="kt_table_widget_5_tab_2_sag">
                            <!--begin::Table container-->
                            <div class="table-responsive">
                              <!--begin::Table-->
                              <table class="
                                  table table-row-dashed table-row-gray-200
                                  align-middle
                                  gs-0
                                  gy-4
                                ">
                                <!--begin::Table head-->
                                <thead>
                                  <tr class="border-0">
                                    <th class="p-2 min-w-100px">
                                      {{ $t("dashboard.asset") }}
                                    </th>
                                    <th class="p-2">APY</th>
                                    <th class="p-2 pe-0 text-end">
                                      {{ $t("dashboard.debitbalance") }}
                                    </th>
                                    <th class="p-2 pe-0 text-end">
                                      {{ $t("dashboard.oflimit") }}
                                    </th>
                                  </tr>
                                </thead>
                                <!--end::Table head-->
                                <!--begin::Table body-->
                                <tbody>
                                  <tr v-for="(swallet, index) in walletall" :key="index">
                                    <td id="borrow-step" class="pulse pulse-success">
                                      <span class="pulse-ring ms-n1"></span>
                                      <v-tour v-if="
                                          swallet.wallet_short == 'BTC' &&
                                          tourActive == 1
                                        " name="tourtwo" :options="myOptions" :steps="stepsborrow"></v-tour>

                                      <a @click="
                                          selectWallet(swallet.wallet_short)
                                        " href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_create_borrow"
                                        class="
                                          text-dark
                                          fw-bolder
                                          text-hover-primary
                                          mb-1
                                          fs-6
                                        ">{{ swallet.wallet_short }}</a>
                                      <span class="text-muted fw-bold d-block">
                                        <a @click="
                                            selectWallet(swallet.wallet_short)
                                          " href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_create_borrow"
                                          class="
                                            text-muted
                                            fw-bolder
                                            text-hover-primary
                                          ">{{ swallet.wallet_name }}</a>
                                      </span>
                                    </td>
                                    <td class="text-start">
                                      <span :class="`badge-light-${
                                          isSuccessb[swallet.wallet_short]
                                        }`">{{ swallet.wallet_bapy }}%</span>
                                    </td>
                                    <td class="text-end text-muted fw-bold">
                                      {{
                                      parseFloat(
                                      $session.get(
                                      swallet.wallet_short + "_bbalance"
                                      )
                                      ).toFixed(8)
                                      }}
                                      <span class="text-muted fw-bold d-block">{{
                                        parseFloat(
                                        $session.get(
                                        swallet.wallet_short + "_bbalance"
                                        ) * swallet.wallet_price
                                        ).toFixed(2)
                                        }}
                                        USDT</span>
                                    </td>

                                    <td class="text-end">
                                      <span v-if="
                                          (($session.get(
                                            swallet.wallet_short + '_bbalance'
                                          ) *
                                            swallet.wallet_price) /
                                            borrowlimit) *
                                          100
                                        ">{{
                                        (
                                        (($session.get(
                                        swallet.wallet_short + "_bbalance"
                                        ) *
                                        swallet.wallet_price) /
                                        borrowlimit) *
                                        100
                                        ).toFixed(2)
                                        }}%</span>
                                      <span :class="`badge-light-${isSuccessBorrowUst}`" v-else>0%</span>
                                    </td>
                                  </tr>
                                </tbody>
                                <!--end::Table body-->
                              </table>
                            </div>
                            <!--end::Table-->
                          </div>
                          <!--end::Tap pane-->
                        </div>
                      </div>
                      <!--end::Body-->
                    </div>
                    <!--end::Tables Widget 5-->
                  </div>
                </div>
                <!--end::Row-->
              </div>
              <!--end::Container-->
            </div>
            <!--end::Post-->
          </div>
          <!--end::Content-->
          <Footer></Footer>
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Page-->
    </div>
    <!--end::Root-->
    <!--begin::Drawers-->
    <!--begin::Modals-->
    <!--begin::Modal - Create App-->
    <div class="modal fade" id="kt_modal_create_depWith" tabindex="-1" aria-hidden="true">
      <!--begin::Modal dialog-->
      <div class="modal-dialog modal-dialog-centered mw-900px">
        <!--begin::Modal content-->
        <div class="modal-content">
          <!--begin::Modal header-->
          <div class="modal-header">
            <!--begin::Modal title-->
            <ul class="nav">
              <li class="nav-item">
                <a class="
                    nav-link
                    btn btn-sm btn-color-muted btn-active btn-active-dark
                    active
                    fw-bolder
                    px-4
                    me-1
                  " data-bs-toggle="tab" href="#kt_table_widget_5_tab_1_deposit">{{ $t("dashboard.deposit") }}</a>
              </li>
              <li class="nav-item">
                <a class="
                    nav-link
                    btn btn-sm btn-color-muted btn-active btn-active-dark
                    fw-bolder
                    px-4
                    me-1
                  " data-bs-toggle="tab" href="#kt_table_widget_5_tab_2_withdraw">{{ $t("dashboard.withdrawal") }}</a>
              </li>
            </ul>
            <!--end::Modal title-->
            <!--begin::Close-->
            <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
              <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
              <span class="svg-icon svg-icon-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)"
                    fill="black" />
                  <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
                </svg>
              </span>
              <!--end::Svg Icon-->
            </div>
            <!--end::Close-->
          </div>
          <!--end::Modal header-->
          <!--begin::Modal body-->
          <div class="tab-content">
            <div class="tab-pane fade show active" id="kt_table_widget_5_tab_1_deposit"
              v-if="walletList[selectWalletShort]">
              <div class="modal-body py-lg-10 px-lg-10">
                <h2 class="text-center mb-5">
                  {{ $t("dashboard.deposit") }} {{ selectWalletShort }}
                </h2>
                <!--begin::Stepper-->
                <div class="
                    stepper stepper-pills stepper-column
                    d-flex
                    flex-column flex-xl-row flex-row-fluid
                  " id="kt_modal_create_app_stepper">
                  <!--begin::Aside-->
                  <div class="
                      d-flex
                      justify-content-center
                      flex-row-auto
                      w-100 w-xl-300px
                    ">
                    <!--begin::Nav-->
                    <div class="stepper-nav ps-lg-10" v-if="
                        walletList[selectWalletShort].wallet_system != 'token'
                      ">
                      <!--begin::Step 1-->
                      <div class="stepper-item current" data-kt-stepper-element="nav">
                        <!--begin::Line-->
                        <div class="stepper-line w-40px"></div>
                        <!--end::Line-->
                        <!--begin::Icon-->
                        <div class="stepper-icon w-40px h-40px">
                          <i class="stepper-check fas fa-check"></i>
                          <span class="stepper-number">1</span>
                        </div>
                        <!--end::Icon-->
                        <!--begin::Label-->
                        <div class="stepper-label w-250px">
                          <h3 class="stepper-title">
                            {{ $t("dashboard.creatingaddress") }}
                          </h3>
                          <div class="stepper-desc">
                            {{ $t("dashboard.addressbutton") }}
                          </div>
                        </div>
                        <!--end::Label-->
                      </div>
                      <!--end::Step 1-->
                      <!--begin::Step 2-->
                      <div class="stepper-item" data-kt-stepper-element="nav">
                        <!--begin::Line-->
                        <div class="stepper-line w-40px"></div>
                        <!--end::Line-->
                        <!--begin::Icon-->
                        <div class="stepper-icon w-40px h-40px">
                          <i class="stepper-check fas fa-check"></i>
                          <span class="stepper-number">2</span>
                        </div>
                        <!--begin::Icon-->
                        <!--begin::Label-->
                        <div class="stepper-label w-250px">
                          <h3 class="stepper-title">
                            {{ $t("dashboard.copyingaddress") }}
                          </h3>
                          <div class="stepper-desc">
                            {{ $t("dashboard.iconaddress") }}
                          </div>
                        </div>
                        <!--begin::Label-->
                      </div>
                      <!--end::Step 2-->
                      <!--begin::Step 3-->
                      <div class="stepper-item" data-kt-stepper-element="nav">
                        <!--begin::Line-->
                        <div class="stepper-line w-40px"></div>
                        <!--end::Line-->
                        <!--begin::Icon-->
                        <div class="stepper-icon w-40px h-40px">
                          <i class="stepper-check fas fa-check"></i>
                          <span class="stepper-number">3</span>
                        </div>
                        <!--end::Icon-->
                        <!--begin::Label-->
                        <div class="stepper-label w-250px">
                          <h3 class="stepper-title">
                            {{ $t("dashboard.sendingdeposit") }}
                          </h3>
                          <div class="stepper-desc">
                            {{
                            $t("dashboard.sendbitcoin").replace(
                            "#degistir#",
                            selectWalletShort
                            )
                            }}
                          </div>
                        </div>
                        <!--end::Label-->
                      </div>
                      <!--end::Step 3-->
                      <!--begin::Step 4-->
                      <div class="stepper-item" data-kt-stepper-element="nav">
                        <!--begin::Line-->
                        <div class="stepper-line w-40px"></div>
                        <!--end::Line-->
                        <!--begin::Icon-->
                        <div class="stepper-icon w-40px h-40px">
                          <i class="stepper-check fas fa-check"></i>
                          <span class="stepper-number">4</span>
                        </div>
                        <!--end::Icon-->
                        <!--begin::Label-->
                        <div class="stepper-label w-250px">
                          <h3 class="stepper-title">
                            {{ $t("dashboard.completion") }}
                          </h3>
                          <div class="stepper-desc">
                            {{ $t("dashboard.followdeposit") }}
                          </div>
                        </div>
                        <!--end::Label-->
                      </div>
                      <!--end::Step 4-->
                      <!--begin::Step 5-->
                      <div class="stepper-item" data-kt-stepper-element="nav">
                        <!--begin::Line-->
                        <div class="stepper-line w-40px"></div>
                        <!--end::Line-->
                        <!--begin::Icon-->
                        <div class="stepper-icon w-40px h-40px bg-danger">
                          <i class="stepper-check fas fa-check"></i>
                          <span class="stepper-number" style="color: #ffffff !important">5</span>
                        </div>
                        <!--end::Icon-->
                        <!--begin::Label-->
                        <div class="stepper-label w-250px">
                          <h3 class="stepper-title">
                            {{ $t("dashboard.warning") }}
                          </h3>
                          <div class="stepper-desc">
                            {{ $t("dashboard.correctaddress") }}
                            <span v-if="
                                walletList[selectWalletShort]
                                  .wallet_tag_system === 1
                              ">{{ $t("dashboard.deposittransactions") }}
                            </span>
                            {{ $t("dashboard.incorrectdeposit") }}
                          </div>
                        </div>
                        <!--end::Label-->
                      </div>
                      <!--end::Step 5-->
                    </div>
                    <!--end::Nav-->

                    <!--begin::Nav-->
                    <div class="stepper-nav ps-lg-10" v-if="
                        walletList[selectWalletShort].wallet_system == 'token'
                      ">
                      <!--begin::Step 1-->
                      <div class="stepper-item current" data-kt-stepper-element="nav">
                        <!--begin::Line-->
                        <div class="stepper-line w-40px"></div>
                        <!--end::Line-->
                        <!--begin::Icon-->
                        <div class="stepper-icon w-40px h-40px">
                          <i class="stepper-check fas fa-check"></i>
                          <span class="stepper-number">1</span>
                        </div>
                        <!--end::Icon-->
                        <!--begin::Label-->
                        <div class="stepper-label w-250px">
                          <h3 class="stepper-title">
                            {{ $t("dashboard.walletlink") }}
                          </h3>
                          <div class="stepper-desc">
                            {{ $t("dashboard.metamaskwallet") }}
                          </div>
                        </div>
                        <!--end::Label-->
                      </div>
                      <!--end::Step 1-->
                      <!--begin::Step 2-->
                      <div class="stepper-item" data-kt-stepper-element="nav">
                        <!--begin::Line-->
                        <div class="stepper-line w-40px"></div>
                        <!--end::Line-->
                        <!--begin::Icon-->
                        <div class="stepper-icon w-40px h-40px">
                          <i class="stepper-check fas fa-check"></i>
                          <span class="stepper-number">2</span>
                        </div>
                        <!--begin::Icon-->
                        <!--begin::Label-->
                        <div class="stepper-label w-250px">
                          <h3 class="stepper-title">
                            {{ $t("dashboard.quantification") }}
                          </h3>
                          <div class="stepper-desc">
                            {{ $t("dashboard.amountdeposit") }}
                          </div>
                        </div>
                        <!--begin::Label-->
                      </div>
                      <!--end::Step 2-->
                      <!--begin::Step 3-->
                      <div class="stepper-item" data-kt-stepper-element="nav">
                        <!--begin::Line-->
                        <div class="stepper-line w-40px"></div>
                        <!--end::Line-->
                        <!--begin::Icon-->
                        <div class="stepper-icon w-40px h-40px">
                          <i class="stepper-check fas fa-check"></i>
                          <span class="stepper-number">3</span>
                        </div>
                        <!--end::Icon-->
                        <!--begin::Label-->
                        <div class="stepper-label w-250px">
                          <h3 class="stepper-title">
                            {{ $t("dashboard.chainselection") }}
                          </h3>
                          <div class="stepper-desc">
                            {{ $t("dashboard.tokenbutton") }}
                          </div>
                        </div>
                        <!--end::Label-->
                      </div>
                      <!--end::Step 3-->
                      <!--begin::Step 4-->
                      <div class="stepper-item" data-kt-stepper-element="nav">
                        <!--begin::Line-->
                        <div class="stepper-line w-40px"></div>
                        <!--end::Line-->
                        <!--begin::Icon-->
                        <div class="stepper-icon w-40px h-40px">
                          <i class="stepper-check fas fa-check"></i>
                          <span class="stepper-number">4</span>
                        </div>
                        <!--end::Icon-->
                        <!--begin::Label-->
                        <div class="stepper-label w-250px">
                          <h3 class="stepper-title">
                            {{ $t("dashboard.completion") }}
                          </h3>
                          <div class="stepper-desc">
                            {{ $t("dashboard.completetransaction") }}
                          </div>
                        </div>
                        <!--end::Label-->
                      </div>
                      <!--end::Step 4-->
                      <!--begin::Step 5-->
                      <div class="stepper-item" data-kt-stepper-element="nav">
                        <!--begin::Line-->
                        <div class="stepper-line w-40px"></div>
                        <!--end::Line-->
                        <!--begin::Icon-->
                        <div class="stepper-icon w-40px h-40px bg-danger">
                          <i class="stepper-check fas fa-check"></i>
                          <span class="stepper-number" style="color: #ffffff !important">5</span>
                        </div>
                        <!--end::Icon-->
                        <!--begin::Label-->
                        <div class="stepper-label w-250px">
                          <h3 class="stepper-title">
                            {{ $t("dashboard.warning") }}
                          </h3>
                          <div class="stepper-desc">
                            {{ $t("dashboard.changedeposit") }}
                            <span v-if="
                                walletList[selectWalletShort]
                                  .wallet_tag_system === 1
                              ">{{ $t("dashboard.deposittransactions") }}
                            </span>
                            {{ $t("dashboard.incorrectdeposit") }}
                          </div>
                        </div>
                        <!--end::Label-->
                      </div>
                      <!--end::Step 5-->
                    </div>
                    <!--end::Nav-->
                  </div>
                  <!--begin::Aside-->
                  <!--begin::Content-->
                  <div class="flex-row-fluid py-lg-5 px-lg-15">
                    <!--begin::Form-->
                    <form class="form" novalidate="novalidate" autocomplete="off" id="kt_modal_create_app_form">
                      <!--begin::Step 1-->
                      <div class="current" data-kt-stepper-element="content">
                        <div class="w-100">
                          <!--begin::Input group-->
                          <div class="fv-row mb-10" v-if="
                              walletList[selectWalletShort]
                                .wallet_tag_system === 1
                            ">
                            <!--begin::Label-->
                            <label class="
                                d-flex
                                align-items-center
                                fs-5
                                fw-bold
                                mb-2
                              ">
                              <span class="required">{{
                                $t("dashboard.tagnumber")
                                }}</span>
                              <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip"
                                :title="$t('dashboard.deposittransactions')"></i>
                            </label>
                            <label class="
                                d-flex
                                align-items-center
                                fs-5
                                fw-bold
                                mb-2
                              ">
                              <input type="text" class="
                                  form-control
                                  form-control-lg
                                  form-control-solid
                                " name="name" v-model="tagNumber" placeholder value />
                              <span class="d-flex flex-column">
                                <span class="symbol symbol-45px ms-2 cursor-pointer"
                                  @click="copyInput(tagNumber, 'Tag Number')">
                                  <span class="symbol-label">
                                    <span class="fa fa-clone" aria-hidden="true"></span>
                                  </span>
                                </span>
                              </span>
                            </label>
                            <!--end::Input-->
                          </div>
                          <div class="fv-row mb-10">
                            <!--begin::Label-->
                            <label v-if="
                                walletList[selectWalletShort].wallet_system !=
                                'token'
                              " class="
                                d-flex
                                align-items-center
                                fs-5
                                fw-bold
                                mb-2
                              ">
                              <span class="required">{{
                                $t("dashboard.address")
                                }}</span>
                              <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip"
                                :title="$t('dashboard.checkaddress')"></i>
                            </label>
                            <label v-if="
                                walletList[selectWalletShort].wallet_system !=
                                'token'
                              " class="
                                d-flex
                                align-items-center
                                fs-5
                                fw-bold
                                mb-2
                              ">
                              <input type="text" class="
                                  form-control
                                  form-control-lg
                                  form-control-solid
                                " name="name" v-model="address" placeholder value />
                              <span class="d-flex flex-column">
                                <span class="symbol symbol-45px ms-2 cursor-pointer"
                                  @click="copyInput(address, 'Address')">
                                  <span class="symbol-label">
                                    <span class="fa fa-clone" aria-hidden="true"></span>
                                  </span>
                                </span>
                              </span>
                            </label>
                            <label @click="
                                dmiktar =
                                  Math.floor(
                                    matemaskWalletBalance * 10000000000
                                  ) / 10000000000
                              " v-if="
                                walletList[selectWalletShort].wallet_system ==
                                  'token' && MetaMaskAddress != ''
                              " class="stepper-desc">{{ $t("dashboard.metamaskwalletbalance") }} :
                              {{
                              Math.floor(
                              matemaskWalletBalance * 10000000000
                              ) / 10000000000
                              }}</label>
                            <label v-if="
                                walletList[selectWalletShort].wallet_system ==
                                  'token' && MetaMaskAddress != ''
                              " class="
                                d-flex
                                align-items-center
                                fs-5
                                fw-bold
                                mb-2
                              ">
                              <span class="required">{{
                                $t("dashboard.amount")
                                }}</span>
                              <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip"
                                :title="$t('dashboard.wantdeposit')"></i>
                            </label>
                            <label v-if="
                                walletList[selectWalletShort].wallet_system ==
                                  'token' && MetaMaskAddress != ''
                              " class="
                                d-flex
                                align-items-center
                                fs-5
                                fw-bold
                                mb-2
                              ">
                              <input type="text" class="
                                  form-control
                                  form-control-lg
                                  form-control-solid
                                " name="name" v-model="dmiktar" placeholder value />
                            </label>
                            <label class="
                                d-flex
                                align-self-center
                                fs-5
                                fw-bold
                                mb-2
                                m-7
                              " v-if="qrcodevalue">
                              <qrcode-vue :value="qrcodevalue" :size="qrcodesize" level="H" class="m-auto m-fitcon" />
                            </label>
                            <!--end::Input-->
                            <div v-if="butongizle == 1">
                              <button v-if="
                                  walletList[selectWalletShort].wallet_system !=
                                  'token'
                                " @click="
                                  getAddress(
                                    selectWalletShort,
                                    walletList[selectWalletShort].wallet_system,
                                    'main'
                                  )
                                " type="button" class="btn btn-primary submit m-5 float-end">
                                {{ selectWalletShort }}
                                {{ $t("dashboard.address").toUpperCase() }}
                              </button>
                              <span v-if="
                                  (connectButton != '' &&
                                    walletList[selectWalletShort]
                                      .wallet_erc20 === 1) ||
                                  (connectButton != '' &&
                                    walletList[selectWalletShort]
                                      .wallet_bep20 === 1)
                                " class="m-auto">{{ $t("dashboard.chainselection") }} :
                                {{ activeChain["NAME"] }}</span>
                              <button class="
                                  btn btn-dark
                                  border-0
                                  float-end
                                  w-100
                                  my-4
                                " type="button" v-if="
                                  (connectButton == '' &&
                                    walletList[selectWalletShort]
                                      .wallet_erc20 === 1 &&
                                    walletList[selectWalletShort]
                                      .wallet_metamask === 1) ||
                                  (connectButton == '' &&
                                    walletList[selectWalletShort]
                                      .wallet_bep20 === 1 &&
                                    walletList[selectWalletShort]
                                      .wallet_metamask === 1)
                                " @click="connectMetamast('Metamask')">
                                <img :src="require('@/assets/images/metamask.png')" class="align-middle" width="40" />
                                {{ $t("dashboard.metamaskconnect") }}
                              </button>
                              <button class="
                                  btn btn-dark
                                  border-0
                                  float-end
                                  w-100
                                  my-4
                                " type="button" v-if="
                                  (connectButton == '' &&
                                    walletList[selectWalletShort]
                                      .wallet_erc20 === 1 &&
                                    walletList[selectWalletShort]
                                      .wallet_trust === 1) ||
                                  (connectButton == '' &&
                                    walletList[selectWalletShort]
                                      .wallet_bep20 === 1 &&
                                    walletList[selectWalletShort]
                                      .wallet_trust === 1)
                                " @click="connectMetamast('Trust')">
                                <img :src="require('@/assets/images/trust.svg')" class="align-middle" width="40" />
                                {{
                                $t("dashboard.metamaskconnect").replace(
                                "METAMASK",
                                "TRUST WALLET"
                                )
                                }}
                              </button>
                              <button class="
                                  btn btn-dark
                                  border-0
                                  float-start
                                  w-100
                                  my-4
                                " type="button" v-if="
                                  (connectButton == 'Metamask' &&
                                    walletList[selectWalletShort]
                                      .wallet_bep20 === 1) ||
                                  (connectButton === 0 &&
                                    walletList[selectWalletShort]
                                      .wallet_erc20 == 'Metamask')
                                " @click="depositMatemask(selectWalletShort)">
                                <img :src="require('@/assets/images/metamask.png')" class="align-middle" width="40" />
                                {{ $t("dashboard.depositing") }}
                                {{ selectWalletShort }}
                                {{ $t("dashboard.withmetamask") }}
                              </button>
                              <button class="
                                  btn btn-dark
                                  border-0
                                  float-start
                                  w-100
                                  my-2
                                " type="button" v-if="
                                  (connectButton == 'Trust' &&
                                    walletList[selectWalletShort]
                                      .wallet_bep20 === 1) ||
                                  (connectButton == 'Trust' &&
                                    walletList[selectWalletShort]
                                      .wallet_erc20 === 1)
                                " @click="depositTrustWallet(selectWalletShort)">
                                <img :src="require('@/assets/images/trust.svg')" class="align-middle" width="40" />
                                {{ $t("dashboard.depositing") }}
                                {{ selectWalletShort }}
                                {{
                                $t("dashboard.withmetamask").replace(
                                "Metamask",
                                "Trust Wallet"
                                )
                                }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--end::Step 1-->
                    </form>
                    <!--end::Form-->
                  </div>
                  <!--end::Content-->
                </div>
                <!--end::Stepper-->
              </div>
            </div>
            <div class="tab-pane fade" id="kt_table_widget_5_tab_2_withdraw">
              <div class="modal-body py-lg-10 px-lg-10" v-if="walletList[selectWalletShort]">
                <!--begin::Stepper-->
                <h2 class="text-center mb-5">
                  {{ $t("dashboard.withdrawal") }} {{ selectWalletShort }}
                </h2>
                <div class="
                    stepper stepper-pills stepper-column
                    d-flex
                    flex-column flex-xl-row flex-row-fluid
                  " id="kt_modal_create_app_stepper">
                  <!--begin::Aside-->
                  <div class="
                      d-flex
                      justify-content-center
                      flex-row-auto
                      w-100 w-xl-300px
                    ">
                    <!--begin::Nav-->
                    <div class="stepper-nav ps-lg-10">
                      <!--begin::Step 1-->
                      <div class="stepper-item current" data-kt-stepper-element="nav">
                        <!--begin::Line-->
                        <div class="stepper-line w-40px"></div>
                        <!--end::Line-->
                        <!--begin::Icon-->
                        <div class="stepper-icon w-40px h-40px">
                          <i class="stepper-check fas fa-check"></i>
                          <span class="stepper-number">1</span>
                        </div>
                        <!--end::Icon-->
                        <!--begin::Label-->
                        <div class="stepper-label w-250px">
                          <h3 class="stepper-title">
                            {{ $t("dashboard.withdrawstart") }}
                          </h3>
                          <div class="stepper-desc">
                            {{ $t("dashboard.withdrawaddress") }}
                            <span v-if="
                                walletList[selectWalletShort]
                                  .wallet_tag_system === 1
                              ">{{ $t("dashboard.yourtagnumber") }})</span>
                            {{ $t("dashboard.requiredfield") }}
                          </div>
                        </div>
                        <!--end::Label-->
                      </div>
                      <!--end::Step 1-->
                      <!--begin::Step 2-->
                      <div class="stepper-item" data-kt-stepper-element="nav">
                        <!--begin::Line-->
                        <div class="stepper-line w-40px"></div>
                        <!--end::Line-->
                        <!--begin::Icon-->
                        <div class="stepper-icon w-40px h-40px">
                          <i class="stepper-check fas fa-check"></i>
                          <span class="stepper-number">2</span>
                        </div>
                        <!--begin::Icon-->
                        <!--begin::Label-->
                        <div class="stepper-label w-250px">
                          <h3 class="stepper-title">
                            {{ $t("dashboard.quantification") }}
                          </h3>
                          <div class="stepper-desc">
                            {{ $t("dashboard.wantowithdraw") }}
                          </div>
                        </div>
                        <!--begin::Label-->
                      </div>
                      <!--end::Step 2-->
                      <!--begin::Step 3-->
                      <div class="stepper-item" data-kt-stepper-element="nav">
                        <!--begin::Line-->
                        <div class="stepper-line w-40px"></div>
                        <!--end::Line-->
                        <!--begin::Icon-->
                        <div class="stepper-icon w-40px h-40px">
                          <i class="stepper-check fas fa-check"></i>
                          <span class="stepper-number">3</span>
                        </div>
                        <!--end::Icon-->
                        <!--begin::Label-->
                        <div class="stepper-label w-250px">
                          <h3 class="stepper-title">
                            {{ $t("dashboard.infrastructure") }}
                          </h3>
                          <div class="stepper-desc">
                            {{ $t("dashboard.selectinfrastructure") }}
                          </div>
                        </div>
                        <!--end::Label-->
                      </div>
                      <!--end::Step 3-->
                      <!--begin::Step 4-->
                      <div class="stepper-item" data-kt-stepper-element="nav">
                        <!--begin::Line-->
                        <div class="stepper-line w-40px"></div>
                        <!--end::Line-->
                        <!--begin::Icon-->
                        <div class="stepper-icon w-40px h-40px">
                          <i class="stepper-check fas fa-check"></i>
                          <span class="stepper-number">4</span>
                        </div>
                        <!--end::Icon-->
                        <!--begin::Label-->
                        <div class="stepper-label w-250px">
                          <h3 class="stepper-title">
                            {{ $t("dashboard.success") }}
                          </h3>
                          <div class="stepper-desc">
                            {{ $t("dashboard.presswithdraw") }}
                          </div>
                        </div>
                        <!--end::Label-->
                      </div>
                      <!--end::Step 4-->
                      <!--begin::Step 5-->
                      <div class="stepper-item" data-kt-stepper-element="nav">
                        <!--begin::Line-->
                        <div class="stepper-line w-40px"></div>
                        <!--end::Line-->
                        <!--begin::Icon-->
                        <div class="stepper-icon w-40px h-40px bg-danger">
                          <i class="stepper-check fas fa-check"></i>
                          <span class="stepper-number" style="color: #ffffff !important">5</span>
                        </div>
                        <!--end::Icon-->
                        <!--begin::Label-->
                        <div class="stepper-label w-250px">
                          <h3 class="stepper-title">
                            {{ $t("dashboard.warning") }}
                          </h3>
                          <div class="stepper-desc">
                            {{ $t("dashboard.incorrectwithdrawal") }}
                          </div>
                        </div>
                        <!--end::Label-->
                      </div>
                      <!--end::Step 5-->
                    </div>
                    <!--end::Nav-->
                  </div>
                  <!--begin::Aside-->
                  <!--begin::Content-->
                  <div class="flex-row-fluid py-lg-5 px-lg-15">
                    <!--begin::Form-->
                    <form class="form" novalidate="novalidate" autocomplete="off" id="kt_modal_create_app_form">
                      <!--begin::Step 1-->
                      <div class="current" data-kt-stepper-element="content" v-if="walletList[selectWalletShort]">
                        <div class="w-100">
                          <!--begin::Input group-->
                          <div class="fv-row mb-10" v-if="
                              walletList[selectWalletShort]
                                .wallet_tag_system === 1
                            ">
                            <!--begin::Label-->
                            <label class="
                                d-flex
                                align-items-center
                                fs-5
                                fw-bold
                                mb-2
                              ">
                              <span class="required">{{
                                $t("dashboard.tagnumber")
                                }}</span>
                              <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip"
                                :title="$t('dashboard.losttagnumber')"></i>
                            </label>
                            <label class="
                                d-flex
                                align-items-center
                                fs-5
                                fw-bold
                                mb-2
                              ">
                              <input type="text" class="
                                  form-control
                                  form-control-lg
                                  form-control-solid
                                " name="tag" v-model="wtagNumber" autocomplete="nope" />
                            </label>
                            <!--end::Input-->
                          </div>
                          <div class="fv-row mb-10">
                            <!--begin::Label-->
                            <label class="
                                d-flex
                                align-items-center
                                fs-5
                                fw-bold
                                mb-2
                              ">
                              <span class="required">{{
                                $t("dashboard.address")
                                }}</span>
                              <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip"
                                :title="$t('dashboard.correctwithdraw')"></i>
                            </label>
                            <label class="
                                d-flex
                                align-items-center
                                fs-5
                                fw-bold
                                mb-2
                              ">
                              <input type="text" class="
                                  form-control
                                  form-control-lg
                                  form-control-solid
                                " name="address" v-model="waddress" autocomplete="off" />
                            </label>
                            <!--end::Input-->
                          </div>
                          <div class="fv-row mb-10">
                            <!--begin::Label-->
                            <label class="
                                d-flex
                                align-items-center
                                fs-5
                                fw-bold
                                mb-2
                              ">
                              <span class="required">{{
                                $t("dashboard.amount")
                                }}</span>
                              <i v-if="maxWithdrawAmount" @click="wmiktar = maxWithdrawAmount.toFixed(15)"
                                class="ms-2 fs-7" data-bs-toggle="tooltip" title="Wallet Balance">{{
                                maxWithdrawAmount.toFixed(15) }}</i>
                            </label>
                            <label class="
                                d-flex
                                align-items-center
                                fs-5
                                fw-bold
                                mb-2
                              ">
                              <input type="text" class="
                                  form-control
                                  form-control-lg
                                  form-control-solid
                                " name="amount" v-model="wmiktar" v-on:keyup="maxWithdraw(selectWalletShort)" />
                            </label>
                            <label class="
                                d-flex
                                align-items-center
                                fs-5
                                fw-bold
                                mb-6
                              ">
                              <span class="fs-9">{{ $t("dashboard.minwithdraw") }}:</span>
                              <i class="ms-1 me-2 fs-9" data-bs-toggle="tooltip" :title="$t('dashboard.minwithdraw')">{{
                                walletList[selectWalletShort].wallet_min_with
                                }}
                                {{ selectWalletShort }}</i>
                              <span class="fs-9">{{ $t("dashboard.maxwithdraw") }}:</span>
                              <i class="ms-1 me-2 fs-9" data-bs-toggle="tooltip" title="$t('dashboard.maxwithdraw')">{{
                                walletList[selectWalletShort].wallet_max_with
                                }}
                                {{ selectWalletShort }}</i>
                            </label>
                            <!--end::Input-->
                            <div class="fv-row mb-10">
                              <!--begin::Label-->
                              <label class="
                                  d-flex
                                  align-items-center
                                  fs-5
                                  fw-bold
                                  mb-2
                                ">
                                <div class="
                                    d-flex
                                    justify-content-between
                                    w-100
                                    mt-auto
                                    mb-2
                                  ">
                                  <span class="fw-bold fs-6 text-gray-400">{{
                                    $t("dashboard.borrowlimit")
                                    }}</span>
                                  <span class="fw-bolder fs-6">{{ withdrawyuzde.toFixed(2) }}%</span>
                                </div>
                              </label>
                              <label class="
                                  d-flex
                                  align-items-center
                                  fs-5
                                  fw-bold
                                  mb-2
                                ">
                                <div class="h-5px mx-3 w-100 bg-light mb-3">
                                  <div :class="`bg-${isSuccessWithdraw} rounded h-5px`" role="progressbar"
                                    :style="`width: ${withdrawyuzde}%;`" aria-valuenow="20" aria-valuemin="0"
                                    aria-valuemax="100"></div>
                                </div>
                              </label>
                              <!--end::Input-->
                            </div>
                            <!--begin::Label-->

                            <div class="col-12 mt-5">
                              <label class="
                                  d-flex
                                  align-items-center
                                  fs-5
                                  fw-bold
                                  mb-2
                                ">
                                <span class="required">{{
                                  $t("dashboard.chain")
                                  }}</span>
                              </label>
                              <select class="form-select form-select-solid" v-model="withselect">
                                <option v-if="
                                    walletList[selectWalletShort]
                                      .wallet_system != 'token'
                                  " :value="selectWalletShort">
                                  {{ selectWalletShort }}
                                </option>
                                <option v-if="
                                    walletList[selectWalletShort]
                                      .wallet_bep20 === 1
                                  " :value="'BEP20'">
                                  BEP20
                                </option>
                                <option v-if="
                                    walletList[selectWalletShort]
                                      .wallet_erc20 === 1
                                  " :value="'ERC20'">
                                  ERC20
                                </option>
                              </select>

                              <div class="
                                  fv-plugins-message-container
                                  invalid-feedback
                                "></div>
                            </div>
                            <div v-if="$session.get('logMeta')==false"
                            class="col-12 mt-5">
                              <label class="
                                  d-flex
                                  align-items-center
                                  fs-5
                                  fw-bold
                                  mb-2
                                ">
                                <input type="number" class="
                                    form-control
                                    form-control-lg
                                    form-control-solid
                                    rounded-left"
                                  name="name" 
                                  v-model="confirmcodeEmail" 
                                  :placeholder="$t('dashboard.emailcode')"
                                  :maxlength="6"
                                  autocomplete="off"
                                  required />
                                <span class="d-flex flex-column">
                                  <span class="
                                      ms-2
                                      p-1-1
                                      cursor-pointer
                                      btn btn-primary
                                    " @click="getCodeMail()">
                                    <span class="symbol-label">
                                      <span class="fs-7" aria-hidden="true">{{
                                        $t("dashboard.sendcode")
                                        }}</span>
                                    </span>
                                  </span>
                                </span>
                              </label>
                            </div>
                            <div class="
                                d-flex
                                justify-content-between
                                align-items-center
                                fs-7
                                fw-bold
                                mb-2
                              ">
                              <label class="justify-content-start">
                                <span class="fs-6">{{ $t("dashboard.receiveamount") }}:</span>
                                <span v-if="withselect == 'BEP20'">{{
                                  (
                                  wmiktar -
                                  walletList[selectWalletShort]
                                  .wallet_with_bep20_com
                                  ).toFixed(8)
                                  }}
                                  {{ selectWalletShort }}</span>
                                <span v-else-if="withselect == 'ERC20'">{{
                                  (
                                  wmiktar -
                                  walletList[selectWalletShort]
                                  .wallet_with_erc20_com
                                  ).toFixed(8)
                                  }}
                                  {{ selectWalletShort }}</span>
                                <span v-else-if="
                                    withselect != '' &&
                                    withselect != 'BEP20' &&
                                    withselect != 'ERC20'
                                  ">{{
                                  (
                                  wmiktar -
                                  walletList[selectWalletShort]
                                  .wallet_with_com
                                  ).toFixed(8)
                                  }}
                                  {{ selectWalletShort }}</span>
                                <div>
                                  <span class="fs-8">{{ $t("dashboard.commission") }}:</span>
                                  <i v-if="withselect == 'BEP20'" class="ms-1 me-2 fs-8" data-bs-toggle="tooltip"
                                    :title="$t('dashboard.commission')">{{
                                    walletList[selectWalletShort]
                                    .wallet_with_bep20_com
                                    }}
                                    {{ selectWalletShort }}</i>
                                  <i v-else-if="withselect == 'ERC20'" class="ms-1 me-2 fs-8" data-bs-toggle="tooltip"
                                    :title="$t('dashboard.commission')">{{
                                    walletList[selectWalletShort]
                                    .wallet_with_erc20_com
                                    }}
                                    {{ selectWalletShort }}</i>
                                  <i v-else-if="
                                      withselect != '' &&
                                      withselect != 'BEP20' &&
                                      withselect != 'ERC20'
                                    " class="ms-1 me-2 fs-8" data-bs-toggle="tooltip"
                                    :title="$t('dashboard.commission')">{{
                                    walletList[selectWalletShort]
                                    .wallet_with_com
                                    }}
                                    {{ selectWalletShort }}</i>
                                </div>
                              </label>
                              <span class="justify-content-end">
                                <button v-if="withbutton == 1" type="button"
                                  class="btn btn-lg btn-primary m-5 float-end" @click="
                                    withdrawCreate(
                                      selectWalletShort,
                                      wtagNumber,
                                      waddress,
                                      wmiktar,
                                      walletList[selectWalletShort]
                                        .wallet_system,
                                      withselect
                                    )
                                  ">
                                  {{ $t("dashboard.withdraw") }}
                                  <span class="svg-icon svg-icon-3 ms-2 me-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                      fill="none">
                                      <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1"
                                        transform="rotate(-180 18 13)" fill="black" />
                                      <path
                                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                        fill="black" />
                                    </svg>
                                  </span>
                                </button>
                                <div v-if="withbutton == 0" class="justify-content-end">
                                  <button type="button" class="btn btn-lg btn-primary m-5 float-end">
                                    <div class="spinner-border spinner-border-sm" role="status">
                                      <span class="sr-only">Loading...</span>
                                    </div>
                                  </button>
                                </div>
                              </span>
                            </div>
                            <!--end::Input-->
                          </div>
                        </div>
                      </div>
                      <!--end::Step 1-->
                    </form>
                    <!--end::Form-->
                  </div>
                  <!--end::Content-->
                </div>
                <!--end::Stepper-->
              </div>
            </div>
          </div>
          <!--end::Modal body-->
        </div>
        <!--end::Modal content-->
      </div>
      <!--end::Modal dialog-->
    </div>
    <!--end::Modal - Create App-->
    <!--end::Modals-->
    <!--begin::Modals-->
    <!--begin::Modal - Create App-->
    <div class="modal fade" id="kt_modal_create_borrow" tabindex="-1" aria-hidden="true">
      <!--begin::Modal dialog-->
      <div class="modal-dialog modal-dialog-centered mw-900px">
        <!--begin::Modal content-->
        <div class="modal-content">
          <!--begin::Modal header-->
          <div class="modal-header">
            <!--begin::Modal title-->
            <ul class="nav">
              <li class="nav-item">
                <a class="
                    nav-link
                    btn btn-sm btn-color-muted btn-active btn-active-dark
                    active
                    fw-bolder
                    px-4
                    me-1
                  " data-bs-toggle="tab" href="#kt_table_widget_5_tab_1_borrow">{{ $t("dashboard.borrow") }}</a>
              </li>
              <li class="nav-item">
                <a class="
                    nav-link
                    btn btn-sm btn-color-muted btn-active btn-active-dark
                    fw-bolder
                    px-4
                    me-1
                  " data-bs-toggle="tab" href="#kt_table_widget_5_tab_2_rborrow">{{ $t("dashboard.repayborrow") }}</a>
              </li>
            </ul>
            <!--end::Modal title-->
            <!--begin::Close-->
            <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
              <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
              <span class="svg-icon svg-icon-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)"
                    fill="black" />
                  <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
                </svg>
              </span>
              <!--end::Svg Icon-->
            </div>
            <!--end::Close-->
          </div>
          <!--end::Modal header-->
          <!--begin::Modal body-->
          <div class="tab-content">
            <div class="tab-pane fade show active" id="kt_table_widget_5_tab_1_borrow">
              <div class="modal-body py-lg-10 px-lg-10">
                <h2 class="text-center mb-5">
                  {{ $t("dashboard.borrow") }} {{ selectWalletShort }}
                </h2>
                <!--begin::Stepper-->
                <div class="
                    stepper stepper-pills stepper-column
                    d-flex
                    flex-column flex-xl-row flex-row-fluid
                  " id="kt_modal_create_app_stepper">
                  <!--begin::Aside-->
                  <div class="
                      d-flex
                      justify-content-center
                      flex-row-auto
                      w-100 w-xl-300px
                    ">
                    <!--begin::Nav-->
                    <div class="stepper-nav ps-lg-10">
                      <!--begin::Step 1-->
                      <div class="stepper-item current" data-kt-stepper-element="nav">
                        <!--begin::Line-->
                        <div class="stepper-line w-40px"></div>
                        <!--end::Line-->
                        <!--begin::Icon-->
                        <div class="stepper-icon w-40px h-40px">
                          <i class="stepper-check fas fa-check"></i>
                          <span class="stepper-number">1</span>
                        </div>
                        <!--end::Icon-->
                        <!--begin::Label-->
                        <div class="stepper-label w-250px">
                          <h3 class="stepper-title">
                            {{ $t("dashboard.borrowamount") }}
                          </h3>
                          <div class="stepper-desc">
                            {{ $t("dashboard.wantborrow") }}
                          </div>
                        </div>
                        <!--end::Label-->
                      </div>
                      <!--end::Step 1-->
                      <!--begin::Step 2-->
                      <div class="stepper-item" data-kt-stepper-element="nav">
                        <!--begin::Line-->
                        <div class="stepper-line w-40px"></div>
                        <!--end::Line-->
                        <!--begin::Icon-->
                        <div class="stepper-icon w-40px h-40px">
                          <i class="stepper-check fas fa-check"></i>
                          <span class="stepper-number">2</span>
                        </div>
                        <!--begin::Icon-->
                        <!--begin::Label-->
                        <div class="stepper-label w-250px">
                          <h3 class="stepper-title">
                            {{ $t("dashboard.verificationcode") }}
                          </h3>
                          <div class="stepper-desc">
                            {{ $t("dashboard.sendcodebutton") }}
                          </div>
                        </div>
                        <!--begin::Label-->
                      </div>
                      <!--end::Step 2-->
                      <!--begin::Step 3-->
                      <div class="stepper-item" data-kt-stepper-element="nav">
                        <!--begin::Line-->
                        <div class="stepper-line w-40px"></div>
                        <!--end::Line-->
                        <!--begin::Icon-->
                        <div class="stepper-icon w-40px h-40px">
                          <i class="stepper-check fas fa-check"></i>
                          <span class="stepper-number">3</span>
                        </div>
                        <!--end::Icon-->
                        <!--begin::Label-->
                        <div class="stepper-label w-250px">
                          <h3 class="stepper-title">
                            {{ $t("dashboard.completion") }}
                          </h3>
                          <div class="stepper-desc">
                            {{ $t("dashboard.amountborrowed") }}
                          </div>
                        </div>
                        <!--end::Label-->
                      </div>
                      <!--end::Step 3-->
                      <!--begin::Step 5-->
                      <div class="stepper-item" data-kt-stepper-element="nav">
                        <!--begin::Line-->
                        <div class="stepper-line w-40px"></div>
                        <!--end::Line-->
                        <!--begin::Icon-->
                        <div class="stepper-icon w-40px h-40px bg-danger">
                          <i class="stepper-check fas fa-check"></i>
                          <span class="stepper-number" style="color: #ffffff !important">5</span>
                        </div>
                        <!--end::Icon-->
                        <!--begin::Label-->
                        <div class="stepper-label w-250px">
                          <h3 class="stepper-title">
                            {{ $t("dashboard.warning") }}
                          </h3>
                          <div class="stepper-desc">
                            {{ $t("dashboard.setdebtlimit") }}
                          </div>
                        </div>
                        <!--end::Label-->
                      </div>
                      <!--end::Step 5-->
                    </div>
                    <!--end::Nav-->
                  </div>
                  <!--begin::Aside-->
                  <!--begin::Content-->
                  <div class="flex-row-fluid py-lg-5 px-lg-15">
                    <!--begin::Form-->
                    <form class="form" novalidate="novalidate" autocomplete="off" id="kt_modal_create_app_form">
                      <!--begin::Step 1-->
                      <div class="current" data-kt-stepper-element="content">
                        <div class="w-100">
                          <!--begin::Input group-->
                          <div class="fv-row mb-10">
                            <!--begin::Label-->
                            <label class="
                                d-flex
                                align-items-center
                                fs-5
                                fw-bold
                                mb-2
                              ">
                              <span class="required">{{
                                $t("dashboard.borrowamount")
                                }}</span>
                              <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip"
                                :title="$t('dashboard.needborrow')"></i>
                            </label>
                            <label class="
                                d-flex
                                align-items-center
                                fs-5
                                fw-bold
                                mb-2
                              ">
                              <input type="text" class="
                                  form-control
                                  form-control-lg
                                  form-control-solid
                                " name="name" v-model="borrowAmount" placeholder
                                v-on:keyup="maxAmountHesapla(selectWalletShort)" />
                              <span class="d-flex flex-column">
                                <span class="symbol symbol-45px ms-2 cursor-pointer"
                                  @click="maxAmount(selectWalletShort)">
                                  <span class="symbol-label">
                                    <span class aria-hidden="true">Max</span>
                                  </span>
                                </span>
                              </span>
                            </label>
                            <!--end::Input-->
                          </div>
                          <div class="fv-row mb-10">
                            <!--begin::Label-->
                            <label class="
                                d-flex
                                align-items-center
                                fs-5
                                fw-bold
                                mb-2
                              ">
                              <div class="
                                  d-flex
                                  justify-content-between
                                  w-100
                                  mt-auto
                                  mb-2
                                ">
                                <span class="fw-bold fs-6 text-gray-400">{{
                                  $t("dashboard.borrowlimit")
                                  }}</span>
                                <span class="fw-bolder fs-6">{{ borrowyuzde.toFixed(2) }}%</span>
                              </div>
                            </label>
                            <label class="
                                d-flex
                                align-items-center
                                fs-5
                                fw-bold
                                mb-2
                              ">
                              <div class="h-5px mx-3 w-100 bg-light mb-3">
                                <div :class="`bg-${isSuccessBorrow} rounded h-5px`" role="progressbar"
                                  :style="`width: ${borrowyuzde}%;`" aria-valuenow="20" aria-valuemin="0"
                                  aria-valuemax="100"></div>
                              </div>
                            </label>
                            <!--end::Input-->
                          </div>
                          <div class="fv-row mb-10">
                            <!--begin::Label-->
                            <label v-if="$session.get('logMeta')==false" class="
                                d-flex
                                align-items-center
                                fs-5
                                fw-bold
                                mb-2
                              ">
                              <span class="required">{{
                                $t("dashboard.confirmationcode")
                                }}</span>
                              <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip"
                                :title="$t('dashboard.checksendcode')"></i>
                            </label>
                            <label v-if="$session.get('logMeta')==false" class="
                                d-flex
                                align-items-center
                                fs-5
                                fw-bold
                                mb-2
                              ">
                              <input type="text" class="
                                  form-control
                                  form-control-lg
                                  form-control-solid
                                " name="name" v-model="confirmcode" :placeholder="$t('dashboard.emailcode')"
                                maxlength="6" />
                              <span class="d-flex flex-column">
                                <span class="
                                    ms-2
                                    p-1-1
                                    cursor-pointer
                                    btn btn-primary
                                  " @click="getCodeMail()">
                                  <span class="symbol-label">
                                    <span class="fs-7" aria-hidden="true">{{
                                      $t("dashboard.sendcode")
                                      }}</span>
                                  </span>
                                </span>
                              </span>
                            </label>
                            <!--end::Input-->
                            <button type="button" class="btn btn-lg btn-primary m-5" v-if="borrowbutton == 1"
                              @click="inBorrow(selectWalletShort)">
                              {{ selectWalletShort }}
                              {{ $t("dashboard.borrow") }}
                              <span class="svg-icon svg-icon-3 ms-2 me-0">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none">
                                  <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1"
                                    transform="rotate(-180 18 13)" fill="black" />
                                  <path
                                    d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                    fill="black" />
                                </svg>
                              </span>
                            </button>
                              <button v-if="borrowbutton == 0" type="button" class="btn btn-lg btn-primary m-5">
                                <div class="spinner-border spinner-border-sm" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </button>
                          </div>
                        </div>
                      </div>
                      <!--end::Step 1-->
                    </form>
                    <!--end::Form-->
                  </div>
                  <!--end::Content-->
                </div>
                <!--end::Stepper-->
              </div>
            </div>
            <div class="tab-pane fade" id="kt_table_widget_5_tab_2_rborrow">
              <div class="modal-body py-lg-10 px-lg-10">
                <h2 class="text-center mb-5">
                  {{ $t("dashboard.repayborrow") }} {{ selectWalletShort }}
                </h2>
                <!--begin::Stepper-->
                <div class="
                    stepper stepper-pills stepper-column
                    d-flex
                    flex-column flex-xl-row flex-row-fluid
                  " id="kt_modal_create_app_stepper">
                  <!--begin::Aside-->
                  <div class="
                      d-flex
                      justify-content-center
                      flex-row-auto
                      w-100 w-xl-300px
                    ">
                    <!--begin::Nav-->
                    <div class="stepper-nav ps-lg-10">
                      <!--begin::Step 1-->
                      <div class="stepper-item current" data-kt-stepper-element="nav">
                        <!--begin::Line-->
                        <div class="stepper-line w-40px"></div>
                        <!--end::Line-->
                        <!--begin::Icon-->
                        <div class="stepper-icon w-40px h-40px">
                          <i class="stepper-check fas fa-check"></i>
                          <span class="stepper-number">1</span>
                        </div>
                        <!--end::Icon-->
                        <!--begin::Label-->
                        <div class="stepper-label w-250px">
                          <h3 class="stepper-title">
                            {{ $t("dashboard.repayborrowamount") }}
                          </h3>
                          <div class="stepper-desc">
                            {{ $t("dashboard.borrowpayoff") }}
                          </div>
                        </div>
                        <!--end::Label-->
                      </div>
                      <!--end::Step 1-->
                      <!--begin::Step 3-->
                      <div class="stepper-item" data-kt-stepper-element="nav">
                        <!--begin::Line-->
                        <div class="stepper-line w-40px"></div>
                        <!--end::Line-->
                        <!--begin::Icon-->
                        <div class="stepper-icon w-40px h-40px">
                          <i class="stepper-check fas fa-check"></i>
                          <span class="stepper-number">2</span>
                        </div>
                        <!--end::Icon-->
                        <!--begin::Label-->
                        <div class="stepper-label w-250px">
                          <h3 class="stepper-title">
                            {{ $t("dashboard.completion") }}
                          </h3>
                          <div class="stepper-desc">
                            {{ $t("dashboard.deductborrow") }}
                          </div>
                        </div>
                        <!--end::Label-->
                      </div>
                      <!--end::Step 3-->
                    </div>
                    <!--end::Nav-->
                  </div>
                  <!--begin::Aside-->
                  <!--begin::Content-->
                  <div class="flex-row-fluid py-lg-5 px-lg-15">
                    <!--begin::Form-->
                    <form class="form" novalidate="novalidate" autocomplete="off" id="kt_modal_create_app_form">
                      <!--begin::Step 1-->
                      <div class="current" data-kt-stepper-element="content">
                        <div class="w-100">
                          <!--begin::Input group-->
                          <div class="fv-row mb-10">
                            <!--begin::Label-->
                            <label class="
                                d-flex
                                align-items-center
                                fs-5
                                fw-bold
                                mb-2
                              ">
                              <span class="required">{{
                                $t("dashboard.repayborrowamount")
                                }}</span>
                              <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip"
                                :title="$t('dashboard.enteramount')"></i>
                            </label>
                            <label class="
                                d-flex
                                align-items-center
                                fs-5
                                fw-bold
                                mb-2
                              ">
                              <input type="text" class="
                                  form-control
                                  form-control-lg
                                  form-control-solid
                                " name="name" v-model="borrowAmountR" placeholder v-on:keyup="
                                  maxAmountHesaplaR(selectWalletShort)
                                " />
                              <span class="d-flex flex-column">
                                <span class="symbol symbol-45px ms-2 cursor-pointer"
                                  @click="maxAmountR(selectWalletShort)">
                                  <span class="symbol-label">
                                    <span class aria-hidden="true">Max</span>
                                  </span>
                                </span>
                              </span>
                            </label>
                            <label class="text-end" v-if="walletList[selectWalletShort]">
                              <span class="text-muted" v-if="
                                  walletList[selectWalletShort].borrowbalance
                                ">{{ $t("dashboard.borrowamount") }}
                                :
                                {{
                                walletList[selectWalletShort][
                                "borrowbalance"
                                ].toFixed(15)
                                }}</span>
                            </label>

                            <!--end::Input-->
                          </div>
                          <div class="fv-row mb-10">
                            <!--begin::Label-->
                            <label class="
                                d-flex
                                align-items-center
                                fs-5
                                fw-bold
                                mb-2
                              ">
                              <div class="
                                  d-flex
                                  justify-content-between
                                  w-100
                                  mt-auto
                                  mb-2
                                ">
                                <span class="fw-bold fs-6 text-gray-400">{{
                                  $t("dashboard.borrowlimit")
                                  }}</span>
                                <span class="fw-bolder fs-6">{{ borrowyuzdeR.toFixed(2) }}%</span>
                              </div>
                            </label>
                            <label class="
                                d-flex
                                align-items-center
                                fs-5
                                fw-bold
                                mb-2
                              ">
                              <div class="h-5px mx-3 w-100 bg-light mb-3">
                                <div :class="`bg-${isSuccessBorrowR} rounded h-5px`" role="progressbar"
                                  :style="`width: ${borrowyuzdeR}%;`" aria-valuenow="20" aria-valuemin="0"
                                  aria-valuemax="100"></div>
                              </div>
                            </label>
                            <!--end::Input-->
                          </div>
                          <div class="fv-row mb-10">
                            <button type="button" class="btn btn-lg btn-primary m-5" v-if="rborrowbutton == 1"
                              @click="outBorrow(selectWalletShort)">
                              {{ selectWalletShort }}
                              {{ $t("dashboard.repayborrow") }}
                              <span class="svg-icon svg-icon-3 ms-2 me-0">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                  fill="none">
                                  <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1"
                                    transform="rotate(-180 18 13)" fill="black" />
                                  <path
                                    d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                    fill="black" />
                                </svg>
                              </span>
                            </button>
                            <button v-if="rborrowbutton == 0" type="button" class="btn btn-lg btn-primary m-5">
                              <div class="spinner-border spinner-border-sm" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                      <!--end::Step 1-->
                    </form>
                    <!--end::Form-->
                  </div>
                  <!--end::Content-->
                </div>
                <!--end::Stepper-->
              </div>
            </div>
          </div>
          <!--end::Modal body-->
        </div>
        <!--end::Modal content-->
      </div>
      <!--end::Modal dialog-->
    </div>
    <!--end::Modal - Create App-->
    <!--end::Modals-->
    <!--begin::Scrolltop-->
    <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
      <!--begin::Svg Icon | path: icons/duotune/arrows/arr066.svg-->
      <span class="svg-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black" />
          <path
            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
            fill="black" />
        </svg>
      </span>
      <!--end::Svg Icon-->
    </div>
    <!--end::Scrolltop-->
    <!--end::Main-->
  </div>
</template>
<script>
  import Asidemenu from "./Sidebar";
  import Header from "./shares/Header";
  import Footer from "./shares/Footer";
  import QrcodeVue from "qrcode.vue";

  //import json from '../services/price.json'
  import { productMixin } from "../services/mixins";
  export default {
    name: "Dashboard",
    metaInfo: function () {
      return {
        title: this.$t("metatag.titledefault"),
        titleTemplate: "%s | " + this.$t("metatag.titleDetail"),
        meta: [
          { name: "description", content: this.$t("metatag.description") },
          { name: "keywords", content: this.$t("metatag.keywords") },
        ],
      };
    },
    components: {
      Asidemenu,
      Header,
      Footer,
      QrcodeVue,
    },
    mixins: [productMixin],
  };
//setInterval(() => {window.console.clear()}, 2000);
</script>